import { memo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Page, PageHeader } from 'core/ui';
import { hasText } from 'core/utils';

import { UserRoles, useCurrentUser } from 'features/auth';
import { useSessionLocation } from 'features/location';
import { useUserSettings } from 'features/settings';

export const Home = memo(() => {
  const { sessionLocation, sessionLocationOptions } = useSessionLocation();
  const { userSettings, homePageUrl, reactPatientGridPage, legacyBaseUrl } = useUserSettings();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (userSettings == null || sessionLocationOptions == null || currentUser == null || currentUser === 'anonymous') return;

    let redirectUrl: string | null = null;

    // First try and use the settings infrastructure to determine the redirect destination.
    if (hasText(homePageUrl)) {
      const absoluteUrl: string =
        homePageUrl.startsWith('http://') || homePageUrl.startsWith('https://') ? homePageUrl : new URL(homePageUrl, window.location.origin).toString();

      redirectUrl = absoluteUrl;
    }

    if (hasText(redirectUrl)) {
      window.location.href = redirectUrl;
      return; // Not really necessary since setting the window location will cause the page to immediately exit - this is for clarity to indicate that this codepath is a terminal one.
    }

    // If we get here then we need to try and redirect based on whether the user is part of an OPO organization.
    if (
      (currentUser.role === UserRoles.CUSTOMER && currentUser.role === UserRoles.CUSTOMER_ADMIN && sessionLocation?.isOpo) ||
      sessionLocationOptions.at(0)?.isOpo
    ) {
      if (reactPatientGridPage) {
        navigate('/patient-2/');
      } else {
        window.location.href = `${legacyBaseUrl}Patient`;
      }
    }

    // At this point there isn't really a sensible fallback for a redirect.  So the user just gets to chill here.
  }, [currentUser, homePageUrl, legacyBaseUrl, navigate, reactPatientGridPage, sessionLocation?.isOpo, sessionLocationOptions, userSettings]);

  return (
    <Page>
      <PageHeader title="Home" showSessionLocation />
    </Page>
  );
});

Home.displayName = 'Home';
