import { FileUrlService } from 'features/exam/services/file-url-service';
import { FileModel } from 'models';
import styled from 'styled-components';
import { FunctionComponent } from 'react';

export const VideoViewer: FunctionComponent<{ file: FileModel; accessToken: null | string }> = ({ file, accessToken }) => {
  return (
    <StyledVideoWrapper>
      <video controls autoPlay>
        <source src={FileUrlService.resolveFileUrl(file, accessToken)} />
        Your browser does not support the video tag.
      </video>
    </StyledVideoWrapper>
  );
};

const StyledVideoWrapper = styled.div`
  width: 100%;
  video {
    width: 100%;
  }
`;
