import { Stepper as KendoStepper } from '@progress/kendo-react-layout';
import { StepperProps } from './StepperProps';
import { styled } from 'styled-components';

export const Stepper = (props: StepperProps) => {
  return <StyledStepper {...props} />;
};

const StyledStepper = styled(KendoStepper)`
  &&.k-stepper .k-step-indicator {
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.palette.grayscale[0]};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
