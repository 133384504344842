import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { MergeStepDonorConfirm, MergeStepExamMigrate, MergeStepResult } from '.';
import { MergePatientCommonProps } from '../types';

export const MergePatientDirectModal: FunctionComponent<MergePatientCommonProps> = ({
  step,
  dateFormat,
  destPatient,
  selectAll,
  selectedFields,
  srcPatient,
  srcSelectAll,
  destSelectAll,
  destDataState,
  gridDestData,
  gridSrcData,
  dataStateChangeDest,
  dataStateChangeSrc,
  srcDataState,
  isMergeSuccessful,
  destPatientId,
}) => {
  return (
    <>
      {step === 0 && (
        <MergeStepDonorConfirm
          dateFormat={dateFormat}
          destPatient={destPatient}
          destSelectAll={destSelectAll}
          onSelectAll={selectAll}
          selectedFields={selectedFields}
          srcPatient={srcPatient}
          srcSelectAll={srcSelectAll}
        />
      )}
      {step === 1 && (
        <MergeStepExamMigrate
          destDataState={destDataState}
          gridDestData={gridDestData}
          gridSrcData={gridSrcData}
          dataStateChangeDest={dataStateChangeDest}
          dataStateChangeSrc={dataStateChangeSrc}
          srcDataState={srcDataState}
        />
      )}
      {step === 2 && <MergeStepResult isMergeSuccessful={isMergeSuccessful} destPatientId={destPatientId} />}
    </>
  );
};
