import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ExamModel } from 'models';
import { FieldContainer, useFieldValueChangeHandler } from 'core/forms';
import { Button, InputField, MASKED_DATETIME_INPUT_MODES, MaskedDateTimeInputField, styled, SwitchField, TextAreaField, TextAreaProps } from 'core/ui';
import { ExamFormValues } from 'features/exam/types';
import { useEvent } from 'core/hooks';

export const ExamForm = ({
  examForm,
  onSubmit,
  setForm,
  formId,
}: {
  exam: ExamModel | undefined;
  examForm: ExamFormValues | undefined;
  onSubmit: SubmitHandler<ExamFormValues>;
  setForm: (form: ExamFormValues) => void;
  formId: string;
}) => {
  const rhfContext = useForm<ExamFormValues>({ defaultValues: examForm });

  const onChange = useEvent(() => {
    setForm(rhfContext.getValues());
  });

  const handleFormChange = useFieldValueChangeHandler(onChange, rhfContext);

  return (
    <>
      <FormProvider {...rhfContext}>
        <StyledForm
          id={formId}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(onSubmit)}
        >
          <StyledFormDiv>
            <StyledFieldContainer>
              <InputField name="description" label="Description" onChange={handleFormChange} />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <InputField name="customerUid" label="Accession" />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <MaskedDateTimeInputField mode={MASKED_DATETIME_INPUT_MODES.DATE} name="studyDate" label="Exam Date" onChange={handleFormChange} />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <MaskedDateTimeInputField mode={MASKED_DATETIME_INPUT_MODES.TIME} name="studyTime" label="Exam Time" onChange={handleFormChange} />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <SwitchField name="share" label="Share" onChange={handleFormChange} />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <SwitchField name="active" label="Active" onChange={handleFormChange} />
            </StyledFieldContainer>
            <StyledFieldContainer>
              <LargeTextAreaField name="notes" label="Notes" onChange={handleFormChange} />
            </StyledFieldContainer>
          </StyledFormDiv>
        </StyledForm>
      </FormProvider>
    </>
  );
};

ExamForm.displayName = 'ExamForm';

const LargeTextAreaField = (props: TextAreaProps & { name: string }) => {
  return <TextAreaField {...props} rows={5} />;
};

const StyledForm = styled.form`
  display: grid;
  max-width: 90vw;
  > * {
    min-width: 300px;
  }
`;
const baseColumnWidth = '220px';
const gapSize = '1rem';

const StyledFormDiv = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: ${gapSize};

  > * {
    flex: 0 1 ${baseColumnWidth};
    min-width: ${baseColumnWidth};
    max-width: ${baseColumnWidth};
  }

  > :last-child {
    flex: 0 1 calc(3 * ${baseColumnWidth} + 2 * ${gapSize});
    max-width: calc(3 * ${baseColumnWidth} + 2 * ${gapSize});
  }
`;

const StyledFieldContainer = styled(FieldContainer)`
  min-height: 0;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;
