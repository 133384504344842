import { useState } from 'react';
import { useEvent } from 'core/hooks';
import { apiClient } from 'features/api';
import { ExamModel } from 'models';
import { State } from '@progress/kendo-data-query';

type UseExamEditWindowParams = {
  onExamUpdate?: () => void;
  onBaseGridUpdate?: (showLoadingSpinner: boolean, dataStateOverride?: State) => Promise<void>;
};

export const useExamEditWindow = ({ onExamUpdate, onBaseGridUpdate }: UseExamEditWindowParams) => {
  const [showExamEditWindow, setShowExamEditWindow] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState<number | null>(null);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);

  // Function to open the ExamEditWindow
  const openExamEditWindow = useEvent((exam: ExamModel) => {
    if (exam.id && exam.patientId) {
      setSelectedExamId(exam.id);
      setSelectedPatientId(exam.patientId);
      setShowExamEditWindow(true);
    }
  });

  // Function to close the ExamEditWindow
  const closeExamEditWindow = useEvent(() => {
    setShowExamEditWindow(false);
    setSelectedExamId(null);
    setSelectedPatientId(null);
  });

  const handleDeleteShareClick = useEvent(async (id: number) => {
    await apiClient.studyShare.deleteStudyShare(id);
  });

  // Function to handle form submission in the ExamEditWindow
  const handleFormSubmit = useEvent(async () => {
    try {
      if (onExamUpdate) {
        onExamUpdate();
      }
      if (onBaseGridUpdate) {
        await onBaseGridUpdate(true);
      }
    } finally {
      closeExamEditWindow();
    }
  });

  return {
    showExamEditWindow,
    selectedExamId,
    selectedPatientId,
    openExamEditWindow,
    closeExamEditWindow,
    handleFormSubmit,
    handleDeleteShareClick,
  };
};
