import { ComponentType, memo, useState } from 'react';

import { Window as KendoWindow, WindowActionsBar as KendoWindowActionsBar, WindowActionsEvent, WindowMoveEvent } from '@progress/kendo-react-dialogs';
import cn from 'classnames';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { WindowActionsBarProps } from './WindowActionsBarProps';
import { WindowProps } from './WindowProps';

/** Pixels. */
const DEFAULT_OFFSET_TOP = 50;

export const Window = memo<WindowProps>(({ className, initialTop = DEFAULT_OFFSET_TOP, stage, onStageChange, onResize, ...rest }) => {
  const [internalStage, setInternalStage] = useState(typeof stage === 'string' ? stage : 'DEFAULT');
  const [isManuallyResized, setIsManuallyResized] = useState(false);

  const handleStageChange = useEvent((event: WindowActionsEvent) => {
    setInternalStage(event.state ?? 'DEFAULT');
    onStageChange?.(event);
  });

  const handleResize = useEvent((event: WindowMoveEvent) => {
    setIsManuallyResized(true);
    onResize?.(event);
  });

  return (
    <StyledWindow
      className={cn(className, {
        'stage-default': internalStage === 'DEFAULT' || internalStage == null,
        'stage-minimized': internalStage === 'MINIMIZED',
        'stage-fullscreen': internalStage === 'FULLSCREEN',
        'size-auto': !isManuallyResized,
        'size-manual': isManuallyResized,
      })}
      initialTop={initialTop}
      stage={internalStage}
      onStageChange={handleStageChange}
      onResize={handleResize}
      {...rest}
    />
  );
});

Window.displayName = 'Window';

// Note: This component is directly re-exporting because the Kendo Window component has bad internal logic that
// directly looks for this component as a direct child of the Window.  So if it doesn't find it, even if it's
// simply wrapped in a functional component then it won't render correctly.  The padding is off and the horizontal
// line doesn't stretch the full width of the dialog.
export const WindowActionsBar: ComponentType<WindowActionsBarProps> = KendoWindowActionsBar;

WindowActionsBar.displayName = 'WindowActionsBar';

const StyledWindow = styled(KendoWindow)`
  &.stage-default {
    &.size-auto {
      height: ${({ initialHeight }) =>
        initialHeight == null
          ? 'auto' // Make the dialog grow to fit the content.  !important is necessary because Kendo sets the height directly on the element.
          : `${initialHeight}px`} !important;

      // Conditionally add max-height based on the presence of DEFAULT_OFFSET_TOP (initialTop)
      ${({ initialTop }) =>
        initialTop &&
        initialTop > 0 &&
        `
        max-height: calc(100dvh - ${initialTop}px - ${initialTop}px);
      `}

      overflow-y: auto;
    }

    // add border with box shadow
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 15px 15px 15px 15px;
    box-shadow:
      0 0 8px rgba(128, 128, 128, 0.2),
      0 0 4px ${({ theme }) => theme.colors.primary}80;
  }

  &.stage-fullscreen {
    border: none;
    border-radius: initial;
  }

  .k-window-title {
    color: ${({ theme }) => theme.colors.primary};
  }

  .k-window-titlebar {
    border-bottom-color: ${({ theme }) => theme.colors.palette.grayscale[4]};
    font-weight: bold;
  }

  .k-window-content {
    padding: ${({ theme }) => theme.space.spacing40};
  }

  .k-window-actions {
    border-color: ${({ theme }) => theme.colors.palette.grayscale[4]};
    padding: ${({ theme }) => theme.space.spacing40} ${({ theme }) => theme.space.spacing40};
  }
`;
