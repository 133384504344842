import { FunctionComponent, useEffect, useState, useMemo, memo } from 'react';
import { Window } from 'core/ui';
import { styled } from 'styled-components';
import { FileViewer } from './FileViewer';
import { FileModel } from 'models';
import { useApiClient } from 'features/api';
import Card from 'react-bootstrap/Card';
import { usePercentageWindowSize } from 'core/hooks';
import React from 'react';

export const ViewFileWindow: FunctionComponent<{
  file?: FileModel;
  fileId?: number;
  onClose: () => void;
  title: string;
}> = memo(({ title, file, fileId, onClose }) => {
  const apiClient = useApiClient();
  const [currentFile, setCurrentFile] = useState<FileModel | null>(file ?? null);
  const { width, height } = usePercentageWindowSize(80, 100, {
    minWidth: 600,
    maxWidth: 1200,
    minHeight: 600,
    maxHeight: 1600,
  });
  // Fetch the file only if fileId is provided and file is not already passed
  useEffect(() => {
    if (fileId && !file) {
      const fetchFile = async () => {
        const fetchedFile = await apiClient.filesClient.getById(fileId, 'msal-required');
        setCurrentFile(fetchedFile);
      };
      fetchFile();
    }
  }, [apiClient.filesClient, fileId, file]);

  return (
    <Window title={title} initialTop={0} onClose={onClose} initialWidth={width} initialHeight={height} resizable={false} draggable={false} modal>
      <StyledWindowCard>
        <Card.Header>{currentFile?.fileName ?? 'Loading...'}</Card.Header>
        <Card.Body>{currentFile && <StyledFileViewer file={currentFile} overrideMultiMonitor />}</Card.Body>
      </StyledWindowCard>
    </Window>
  );
});

ViewFileWindow.displayName = 'ViewFileWindow';

const StyledWindowCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFileViewer = styled(FileViewer)`
  width: 100%;
  height: 100%;
  display: flex;
`;
