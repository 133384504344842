import { memo, useState } from 'react';

import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Checkbox, ColumnState, Window, WindowActionsBar } from 'core/ui';

import { ExamsGridService } from '../services';
import { ColumnDefinition, ColumnsModalProps } from '../types';

export const ColumnsModal = memo<ColumnsModalProps>(({ className, columns, columnsState, onClose, onSave }) => {
  const [currentColumnsState, setCurrentColumnsState] = useState<Record<string, ColumnState>>(columnsState);

  const handleChange = useEvent((event: CheckboxChangeEvent, column: ColumnDefinition) => {
    const newColumnsState: Record<string, ColumnState> = {
      ...currentColumnsState,
      [column.field]: {
        show: Boolean(event.target.value),
        orderIndex: columnsState[column.field]?.orderIndex ?? 0,
      },
    };

    setCurrentColumnsState(newColumnsState);
  });

  const handleSaveClick = useEvent(() => {
    if (currentColumnsState == null) {
      throw new Error('currentColumnsState state cannot be null when saving.');
    }

    onSave(currentColumnsState);
  });

  return (
    <Window className={className} onClose={onClose} title="Columns" initialWidth={400}>
      <div>
        {columns.map((column) => (
          <ColumnsModalListItem
            key={column.field}
            column={column}
            onChange={handleChange}
            show={Boolean(ExamsGridService.getColumnState(currentColumnsState, column.field).show)}
          />
        ))}
      </div>
      <WindowActionsBar>
        <Button onClick={handleSaveClick}>Apply Changes</Button>
      </WindowActionsBar>
    </Window>
  );
});

ColumnsModal.displayName = 'ColumnsModal';

const ColumnsModalListItem = memo<ColumnsModalListItemProps>(({ column, show, onChange }) => {
  const handleChange = useEvent((event: CheckboxChangeEvent) => {
    onChange(event, column);
  });

  return <StyledCheckbox key={column.field} onChange={handleChange} text={column.title ?? undefined} value={show} valid />;
});

ColumnsModalListItem.displayName = 'ColumnsModalListItem';

type ColumnsModalListItemProps = {
  column: ColumnDefinition;
  show: boolean;
  onChange: (event: CheckboxChangeEvent, column: ColumnDefinition) => void;
};

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;
