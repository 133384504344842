import { FunctionComponent } from 'react';

import { useNavigate } from 'react-router-dom';

import { useInitializationEffect } from 'core/hooks';

import { AuthenticationScheme } from '../constants';
import { useAuthentication, useRouteGuard } from '../hooks';
import { RouteGuardErrorCode } from '../types';
import { SessionLocationRequiredModal } from './SessionLocationRequiredModal';

export const DefaultAuthorizationChallenge: FunctionComponent = () => {
  const { activeScheme, loginOidc, logoutShare } = useAuthentication();
  const { allowedSchemes, errorCodes } = useRouteGuard();
  const navigate = useNavigate();

  useInitializationEffect(() => {
    if (errorCodes.includes(RouteGuardErrorCode.SchemeNotAllowed)) {
      if (activeScheme === AuthenticationScheme.ANONYMOUS && allowedSchemes.includes(AuthenticationScheme.OIDC)) {
        loginOidc();
      }

      if (activeScheme === AuthenticationScheme.SHARE) {
        logoutShare();
        window.location.reload();
      }
    }

    if (errorCodes.includes(RouteGuardErrorCode.MissingRequiredRole) || errorCodes.includes(RouteGuardErrorCode.RequiresSystemAdmin)) {
      if (activeScheme === AuthenticationScheme.OIDC) {
        navigate('/home-2/');
      }
    }
  });

  if (errorCodes.includes(RouteGuardErrorCode.RequiresSessionLocation)) {
    return <SessionLocationRequiredModal />;
  }

  return null;
};

DefaultAuthorizationChallenge.displayName = 'DefaultAuthorizationChallenge';
