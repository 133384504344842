import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import { Outlet, useMatch, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';

import { FileModel, PatientModel, ShareStudyViewer } from 'models';

import { useBoolean, useEvent } from 'core/hooks';
import { AccordionNgProvider, BreakpointSelectors, PAGE_GRID_CLASSES, Page, useAccordionNg, useBreakpoints } from 'core/ui';
import { equalsInsensitive, hasText } from 'core/utils';

import { useApiClient } from 'features/api';
import { useParsedShareAccessToken } from 'features/auth';
import { FileViewer } from 'features/file';
import { PatientVerbiageProvider } from 'features/patient';

import { ShareService } from '../services';
import { ExamFilter, MobileFiltersContext } from '../types';
import { DesktopSideBar } from './DesktopSideBar';
import { MobileBottomBar } from './MobileBottomBar';
import { ShareHeader } from './ShareHeader';

export const Viewer: FunctionComponent = () => {
  const apiClient = useApiClient();
  const shareToken = useParsedShareAccessToken(true);
  const { desktop, mobile, setVerticalClampOverride } = useBreakpoints();
  const filtersPath = useResolvedPath('filters');
  const filtersMatch = useMatch(filtersPath.pathname);

  const accordion = useAccordionNg([]);
  const [patient, setPatient] = useState<PatientModel | null>(null);
  const [exams, setExams] = useState<ShareStudyViewer[]>([]);
  const [showSideBar, { toggle: toggleShowSideBar, setTrue: openSideBar }] = useBoolean(false);
  const [selectedFile, setSelectedFile] = useState<FileModel | null>(null);
  const [filter, setFilter] = useState<ExamFilter>({
    studyTypes: [],
    organs: [],
  });

  const patientVerbiage = equalsInsensitive('OPO', patient?.locationType) ? 'Donor' : 'Patient';

  const studyTypes = useMemo(
    () =>
      uniqWith(
        exams.filter((e) => hasText(e.studyType)).map((e) => e.studyType!),
        (lhs, rhs) => equalsInsensitive(lhs, rhs),
      ),
    [exams],
  );

  const organs = useMemo(() => uniq(exams.map((e) => e.organ)), [exams]);

  const filteredExams = useMemo(() => ShareService.filterExams(exams, filter), [exams, filter]);

  const filtersContext: MobileFiltersContext = useMemo(
    () => ({
      studyTypes: studyTypes ?? [],
      organs,
      filter,
      onFilterChange: setFilter,
    }),
    [filter, organs, studyTypes],
  );

  const initialize = useEvent(async () => {
    const patientResponse = await apiClient.patientClient.getPatient(shareToken.sharePatientId, 'share-required');

    const newExams = await apiClient.studyShare.getStudyShareByLinkId(shareToken.shareLinkId);

    const newExpandedAccordionItems = [];

    if (newExams.length === 1) {
      newExpandedAccordionItems.push(newExams[0].examId.toString());
    }

    const newSelectedFile = newExams.length >= 1 && newExams[0].files.length >= 1 ? newExams[0].files[0] : null;

    setPatient(patientResponse);
    setExams(newExams);
    setSelectedFile(newSelectedFile);

    if (!newSelectedFile) {
      openSideBar();
    }

    accordion.setExpandedItemKeys(newExpandedAccordionItems);
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Vertically clamp the viewport when we are at the normal viewer screen.
  useEffect(() => {
    if (!filtersMatch) {
      setVerticalClampOverride(true);

      return () => {
        setVerticalClampOverride(false);
      };
    }

    return undefined;
  }, [filtersMatch, setVerticalClampOverride]);

  if (patient == null) return null;

  if (filtersMatch && mobile) {
    return (
      <PatientVerbiageProvider mode={patientVerbiage}>
        <Outlet context={filtersContext} />
      </PatientVerbiageProvider>
    );
  }

  return (
    <PatientVerbiageProvider mode={patientVerbiage}>
      <AccordionNgProvider {...accordion}>
        <StyledPage>
          <ShareHeader patient={patient} showSideBar={showSideBar} onShowSideBarToggleClick={toggleShowSideBar} />

          <StyledContentDiv className={PAGE_GRID_CLASSES.Flush}>
            {desktop && (
              <DesktopSideBar
                exams={filteredExams}
                selectedFileId={selectedFile?.id ?? null}
                studyTypes={studyTypes}
                organs={organs}
                filter={filter}
                show={showSideBar}
                onFilterChange={setFilter}
                onFileClick={setSelectedFile}
              />
            )}

            <StyledFileViewerContainer>
              {selectedFile != null && hasText(selectedFile?.url) && <StyledFileViewer file={selectedFile} />}
            </StyledFileViewerContainer>

            {mobile && (
              <StyledMobileBottomBar exams={filteredExams} selectedFileId={selectedFile?.id ?? null} onFileClick={setSelectedFile} show={showSideBar} />
            )}
          </StyledContentDiv>
        </StyledPage>
      </AccordionNgProvider>
    </PatientVerbiageProvider>
  );
};

Viewer.displayName = 'Viewer';

const StyledPage = styled(Page)`
  row-gap: 0;
`;

const StyledContentDiv = styled.div`
  display: grid;
  row-gap: 0;
  overflow-x: hidden;

  ${BreakpointSelectors.VerticalClamp} & {
    overflow-y: hidden;
  }

  ${BreakpointSelectors.Desktop} & {
    grid-template-columns: min-content minmax(500px, 1fr);
    grid-template-rows: 1fr;
    overflow: hidden;
  }

  ${BreakpointSelectors.Mobile} & {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    row-gap: 0;
    overflow-x: hidden;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationPortrait} & {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    row-gap: 0;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationLandscape} & {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    row-gap: 0;
  }

  ${BreakpointSelectors.VerticalClamp} & {
    overflow-y: hidden;
  }
`;

const StyledFileViewerContainer = styled.div`
  overflow: hidden;
`;

const StyledFileViewer = styled(FileViewer)`
  max-width: 100%;
  max-height: 100%;
`;

const StyledMobileBottomBar = styled(MobileBottomBar)`
  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationPortrait} & {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationLandscape} & {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }
`;
