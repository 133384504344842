const radiiScale = [
  '0rem', // 0
  '0.125rem', // 1
  '0.25rem', // 2
  '0.5rem', // 3
  '0.625rem', // 4
  '1rem', // 5
  '6.25rem', // 6
];

const base = radiiScale[2];
const circle = radiiScale[6];

export const radii = {
  scale: radiiScale,
  base,
  circle,
};

export type Radii = typeof radii;
