import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { UnitModel } from 'models';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { useAppDispatch, useAppSelector } from 'features/main/hooks';

import { LocationActions } from '../../location';
import { InitialValues } from '../constants';
import { UnitActions, UnitSelectors } from '../redux';
import { GeneralTabFormValues, IntegrationTabFormValues } from '../types';
import { GeneralTabContent } from './GeneralTabContent';
import { IntegrationTabContent } from './IntegrationTabContent';

const allTabKeys = ['general']; // TODO: Add the 'integration' key here once that tab starts being used.

function copyFormsToModel(
  initialState: Partial<UnitModel> | null | undefined,
  generalForm: GeneralTabFormValues,
  integrationForm: IntegrationTabFormValues,
): UnitModel {
  if (generalForm.unitPCN == null) throw new Error('unitPCN cannot be null.');
  if (generalForm.location == null) throw new Error('location cannot be null.');

  if (initialState == null) {
    return {
      ...InitialValues.nonFormFields,
      ...generalForm,
      ...integrationForm,
      unitPCN: generalForm.unitPCN,
      location: generalForm.location,
    };
  }

  return {
    ...InitialValues.nonFormFields,
    ...initialState,
    ...generalForm,
    ...integrationForm,
    unitPCN: generalForm.unitPCN,
    location: generalForm.location,
  };
}

function copyModelToForms(unit: UnitModel): {
  generalForm: GeneralTabFormValues;
  integrationForm: IntegrationTabFormValues;
} {
  return {
    generalForm: {
      serialNumber: unit.serialNumber ?? '',
      unitPCN: unit.unitPCN,
      location: unit.location,
      modelType: unit.modelType ?? '',
      firmwareRevision: unit.firmwareRevision ?? '',
      user: unit.user ?? '',
      lastInDate: unit.lastInDate ?? '',
      lastOutDate: unit.lastOutDate ?? '',
      expectedInDate: unit.expectedInDate ?? '',
      updated: unit.updated ?? '',
      pfwAssetNumber: unit.pfwAssetNumber ?? '',
      active: unit.active,
    },
    integrationForm: {},
  };
}

export const UnitForm: FunctionComponent = () => {
  const [isIntegrationTabVisible] = useState<boolean>(false); // TODO: This only exists because the integration tab is empty for now.  This hidden logic can be removed once we start utilizing the tab.

  const dispatch = useAppDispatch();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [generalForm, setGeneralForm] = useState(InitialValues.generalForm);
  const [integrationForm, setIntegrationForm] = useState(InitialValues.integrationForm);
  const [isFormReady, setIsFormReady] = useState(false);

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const unit = useAppSelector(UnitSelectors.getById(id));

  const handleSubmit = useCallback(async () => {
    const newUnit = copyFormsToModel(unit, generalForm, integrationForm);

    // eslint-disable-next-line no-useless-catch
    try {
      if (isFormInEditMode) {
        await dispatch(UnitActions.edit(newUnit));
        NotificationsService.displaySuccess(`Device Saved ${newUnit.serialNumber}.`);
      } else {
        await dispatch(UnitActions.add(newUnit));
        NotificationsService.displaySuccess(`Device Created ${newUnit.serialNumber}.`);
      }
      navigate('/unit');
    } catch (ex) {
      throw ex;
    }
  }, [unit, generalForm, integrationForm, isFormInEditMode, navigate, dispatch]);

  useEffect(() => {
    dispatch(LocationActions.getAll(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(UnitActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  useEffect(() => {
    if (isFormInEditMode && unit != null) {
      const newForms = copyModelToForms(unit);

      setGeneralForm(newForms.generalForm);
      setIntegrationForm(newForms.integrationForm);
      setIsFormReady(true);
    }
  }, [isFormInEditMode, unit]);

  useEffect(() => {
    if (!isFormInEditMode) {
      setIsFormReady(true);
    }
  }, [isFormInEditMode]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!isFormReady) return <></>;

  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit Device' : 'New Device'} />
      <div>
        <TabContainer id="unit-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              {isIntegrationTabVisible && (
                <TabNavItem>
                  <TabNavLink eventKey="integration">Integration</TabNavLink>
                </TabNavItem>
              )}
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent initialValues={generalForm} onSubmit={handleSubmit} onChange={setGeneralForm} />
            </TabPane>
            {isIntegrationTabVisible && (
              <TabPane eventKey="integration">
                <IntegrationTabContent initialValues={integrationForm} onSubmit={handleSubmit} onChange={setIntegrationForm} />
              </TabPane>
            )}
          </TabContent>
        </TabContainer>
      </div>
    </Page>
  );
};

UnitForm.displayName = 'UnitForm';
