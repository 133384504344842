import { ComponentType, memo, useEffect, useMemo, useRef, useState } from 'react';

import { faPen, faUser } from '@fortawesome/pro-solid-svg-icons';
import { State } from '@progress/kendo-data-query';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PatientAgeRangeModel, PatientGridModel, PatientModel } from 'models';

import { DataResult } from 'core/api';
import { SubmitHandler } from 'core/forms';
import { useAsyncCallback, useDebounceEmitter, useEvent, useMemoizedComponent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  Action,
  ActionListCell,
  Button,
  ButtonVariants,
  DataTable,
  ExpandableRow,
  HeaderCell,
  mapToExpandableRows,
  Page,
  PageHeader,
  Switch,
  Tooltip,
} from 'core/ui';
import { sizes } from 'core/ui/theme/tokens';
import { findOrThrow, hasText } from 'core/utils';

import { apiClient } from 'features/api';
import { useSessionLocation } from 'features/location';
import { PatientFormValues } from 'features/patient/types';
import { useUserSettings } from 'features/settings';

import { SearchInput } from 'features/exam/fragments/SearchInput';
import { PatientEditService, PatientGridService } from '../services';
import { MergePatientModal } from './MergePatientModal';
import { PatientEditWindow } from './PatientEditWindow';
import { ITransplantModal } from './ITransplantModal';
import { useITransplant } from 'features/patient/hooks';

export const PatientHome = memo(() => {
  const navigate = useNavigate();
  const { reactPatientFormPage, legacyBaseUrl } = useUserSettings(true);

  const gridRef = useRef<HTMLDivElement | null>(null);
  const [dataState, setDataState] = useState<State | null>(null);
  const [patients, setPatients] = useState<DataResult<ExpandableRow<PatientGridModel>> | null>(null);
  const [selectedPatients, setSelectedPatients] = useState<Record<number, boolean>>({});
  const [searchValue, setSearchValue] = useState('');
  const [isMergePatientVisible, setIsMergePatientVisible] = useState(false);
  const [filterByActive, setFilterByActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ageRanges, setAgeRanges] = useState<PatientAgeRangeModel[] | undefined>();
  const [showEditModal, setShowAddWindow] = useState(false);
  const [patientEditMode, setPatientEditMode] = useState(false);
  const [activePatient, setActivePatient] = useState<PatientModel | undefined>();
  const { sessionLocation, setSessionLocation, sessionLocationOptions } = useSessionLocation(false);

  const { iTransplantData, isITransplantVisible, toggleITransplantDialog, handleITransplantStatusChange, handleITransplantAdd, handleITransplantUpdate } =
    useITransplant();

  const selectedPatientsList = useMemo(() => {
    return Object.entries(selectedPatients)
      .filter(([, selected]) => selected)
      .map(([id]) => patients?.data.find((p) => Number.parseInt(id, 10) === p.id))
      .filter((p) => p != null);
  }, [patients?.data, selectedPatients]);

  const [fetchPatients] = useAsyncCallback(async (signal, showLoadingSpinner: boolean, dataStateOverride?: State, filterByActiveOverride?: boolean) => {
    if (dataStateOverride == null && dataState == null) {
      throw new Error('Cannot fetch patients because the dataState is null and a dataStateOverride was not specified.');
    }

    const queryDataState =
      hasText(searchValue) || filterByActiveOverride != null
        ? PatientGridService.getQueryDataState2(dataStateOverride ?? dataState!, searchValue, filterByActiveOverride ?? filterByActive) // Non-null assertion because TS doesn't seem to be able to infer that both dataStateOverride and dataState cannot both be null.
        : (dataStateOverride ?? dataState!);

    try {
      if (showLoadingSpinner) {
        setIsLoading(true);
      }

      // filter patients by selected location
      if (queryDataState?.filter?.filters) {
        queryDataState.filter.filters = queryDataState.filter.filters.filter((f) => 'field' in f && f.field !== 'Location_Id');

        // Check if any filter applies to the disallowed field
        if (!queryDataState.filter.filters) {
          if (sessionLocation?.id != null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            queryDataState.filter.filters = [
              {
                field: 'Location_Id',
                operator: 'eq',
                value: sessionLocation?.id,
              },
              {
                field: 'active',
                operator: 'eq',
                value: filterByActiveOverride ?? filterByActive,
              },
            ];
          }
        } else {
          const filter = [
            {
              field: 'active',
              operator: 'eq',
              value: filterByActiveOverride ?? (filterByActive as boolean | number | string),
            },
          ];
          if (sessionLocation) {
            filter.push({
              field: 'Location_Id',
              operator: 'eq',
              value: sessionLocation?.id,
            });
          }
          queryDataState.filter.filters = filter;
        }
      }

      const newPatients = mapToExpandableRows(await apiClient.patientClient.getAllForKendoGrid(queryDataState, null, signal));
      setPatients(newPatients);
    } finally {
      if (showLoadingSpinner) {
        setIsLoading(false);
      }
    }
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchPatients(true);
  }, 500);

  const initialize = useEvent(() => {
    // We need to perform a partial reset of the page.  We want to keep the search filters, but clear out the grid data, selected patients, and pager state.
    // This is relevant when the page is already loaded and then the user changes the selected session location.
    const { dataState: initialDataState } = PatientGridService.initializePatientGridState();
    const newDataState: State = dataState == null ? initialDataState : { ...dataState, skip: initialDataState.skip, take: initialDataState.take };

    clearDebounce();
    setDataState(newDataState);
    setPatients(null);
    setSelectedPatients({});

    fetchPatients(true, newDataState);
  });

  useEffect(() => {
    initialize();
  }, [initialize, sessionLocation?.id]);

  useEffect(() => {
    gridRef.current = document.querySelector('.k-grid');
  });

  useEffect(() => {
    const fetchAgeRanges = async () => {
      const patientAgeRangeResponse = await apiClient.patientClient.getPatientAgeRange();
      setAgeRanges(patientAgeRangeResponse);
    };

    fetchAgeRanges();
  }, []);

  const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
    let isEventFromPager = false;
    let isEventFromSortClick = false;

    // Determine if the event originates within the pager.
    if (event.syntheticEvent.target instanceof Element) {
      isEventFromPager = event.syntheticEvent.target.closest('.k-pager') != null;
    }

    // Determine if the event is the result of the user clicking a sortable header cell.
    if (event.syntheticEvent.type === 'click' && event.syntheticEvent.target instanceof Element) {
      // We have to make sure we are within the separate <table> element for the headers, but we also have to make sure we aren't in the filter row.
      isEventFromSortClick =
        event.syntheticEvent.target.closest('.k-grid-header-table') != null && event.syntheticEvent.target.closest('.k-filter-row') == null;
    }

    setDataState(event.dataState);

    if (isEventFromPager || isEventFromSortClick) {
      clearDebounce();
      fetchPatients(true, event.dataState);
    } else {
      emitDebounce();
    }
  });

  const handleQuickEditClick = useEvent(async (_event: unknown, patient: PatientGridModel) => {
    const patientResponse = await apiClient.patientClient.getPatient(patient.id);
    setActivePatient(patientResponse);
    setPatientEditMode(true);
    setShowAddWindow(true);
  });

  const handleEditClick = useEvent((_event: unknown, patient: PatientGridModel) => {
    if (sessionLocationOptions == null) throw new Error('Cannot proceed because sessionLocationOptions is null.');

    setSessionLocation(
      findOrThrow(
        sessionLocationOptions,
        (l) => l.id === patient.location_Id,
        `Could not automatically set the session location for patient: ${patient.id}.  The current user does not have access to location: ${patient.location_Id ?? 'null'}.`,
      ),
    );

    if (reactPatientFormPage) {
      navigate(`/patient-2/edit/${patient.id}`);
    } else {
      window.location.href = `${legacyBaseUrl}/patient/edit/${patient.id}`;
    }
  });

  const toggleMergeDonorModal = () => {
    setIsMergePatientVisible(!isMergePatientVisible);
    if (isMergePatientVisible) {
      initialize();
    }
  };

  const handleFilterByActiveChange = useEvent((event: SwitchChangeEvent) => {
    if (dataState == null) throw new Error('Cannot proceed because the dataState is null.');

    setFilterByActive(event.value);
    clearDebounce();
    fetchPatients(true, undefined, event.value);
  });

  const handleMerge = useEvent(async () => {
    // TODO: Implement this.
  });

  const handleAddNewPatientClick = useEvent(() => {
    setActivePatient(undefined);
    setPatientEditMode(false);
    setShowAddWindow(true);
  });

  const handleSubmit: SubmitHandler<PatientFormValues> = useEvent(async (data) => {
    if (sessionLocation == null && !data.id) {
      throw new Error('Cannot proceed because the sessionLocation is null.');
    }
    try {
      setIsSubmitting(true);
      const updatedPatientModel = PatientEditService.copyFormToModel(data, {} as PatientModel);
      //use the session location for new patients
      if (!data.id) {
        updatedPatientModel.location_Id = sessionLocation?.id ?? null;
      }
      await apiClient.patientClient.savePatient(updatedPatientModel);
      NotificationsService.displaySuccess('Donor updated successfully');
      await fetchPatients(true);
    } catch (error) {
      NotificationsService.displayError('Error updating donor');
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setShowAddWindow(false);
    }
  });

  const columnWidthTotal = PatientGridService.getPatientColumns().reduce((acc, column) => acc + parseInt(column.width?.replace('px', '') ?? '0', 10), 0);
  const NotesCell = useMemoizedComponent<GridCellProps>(
    ({ dataItem }) => {
      const gridWidth = gridRef.current?.offsetWidth ?? 0;
      const maxLength = Math.max(5, Math.min(gridWidth - columnWidthTotal - 200, 250));
      const value = dataItem.notes as string | undefined;
      if (value && value.length > maxLength) {
        return <td title={value}>{value?.slice(0, maxLength) + '...'}</td>;
      } else {
        return <td title={value}>{value}</td>;
      }
    },
    [columnWidthTotal],
    'CellWithTooltip',
  );

  const gridActions: Action[] = useMemo(
    () => [
      {
        key: 'quickedit-patient',
        title: 'Quick Edit',
        icon: faPen,
        onClick: handleQuickEditClick,
      },
      {
        key: 'edit-patient',
        title: 'Edit',
        icon: faUser,
        onClick: handleEditClick,
      },
    ],
    [handleEditClick],
  );

  if (dataState == null) return null;

  return (
    <Page>
      <PageHeader showSessionLocation title={sessionLocation?.isOpo ? 'Donors' : 'Patients'} />
      <StyledMainContainer>
        <StyledActionsBar>
          <SearchInput value={searchValue} onChange={setSearchValue} placeholder="Search" />
          <StyledRightSideActions>
            <Tooltip text={selectedPatientsList.length !== 2 ? `Select 2 ${sessionLocation?.isOpo ? 'donors' : 'patients'} to merge` : ''}>
              <span>
                <Button onClick={toggleMergeDonorModal} disabled={selectedPatientsList.length !== 2} variant={ButtonVariants.SECONDARY}>
                  Merge {sessionLocation?.isOpo ? 'Donor' : 'Patient'}
                </Button>
              </span>
            </Tooltip>{' '}
            <Button variant={ButtonVariants.SECONDARY} type="button" onClick={toggleITransplantDialog}>
              Upload iTransplant
            </Button>
            <Button type="button" onClick={handleAddNewPatientClick}>
              Add New {sessionLocation?.isOpo ? 'Donor' : 'Patient'}
            </Button>
            <Switch label="Active" onChange={handleFilterByActiveChange} value={filterByActive} />
          </StyledRightSideActions>
        </StyledActionsBar>

        <StyledDataTable
          {...dataState}
          data={patients}
          sortable
          filterable
          selectable
          pageable
          total={patients?.total}
          selectedState={selectedPatients}
          isLoading={isLoading}
          actions={gridActions}
          onDataStateChange={handleDataStateChange}
          onSelectionChange={setSelectedPatients}
          rowHeight={sizes.dataTableRowHeightMedium}
        >
          <GridColumn
            field="action"
            filterable={false}
            headerCell={HeaderCell}
            reorderable={false}
            sortable={false}
            title="Action"
            width="80px"
            cell={ActionListCell as ComponentType<GridCellProps>}
          />
          {PatientGridService.getPatientColumns().map((column) =>
            column.field === 'notes' ? (
              <GridColumn key={column.field} {...column} headerCell={HeaderCell} cell={NotesCell} filter={column.columnFilter} />
            ) : (
              <GridColumn
                key={column.field}
                headerCell={HeaderCell}
                cell={column.cell}
                filter={column.columnFilter}
                field={column.field}
                title={column.title}
                width={column.width}
              />
            ),
          )}
        </StyledDataTable>
      </StyledMainContainer>
      {isMergePatientVisible && selectedPatientsList.length === 2 && (
        <MergePatientModal
          isOPO={sessionLocation?.isOpo ?? false}
          key={`${selectedPatientsList[1].id}-${selectedPatientsList[0].id}`}
          srcDonorId={selectedPatientsList[1].id}
          destDonorId={selectedPatientsList[0].id}
          toggleDialog={toggleMergeDonorModal}
          onMerge={handleMerge}
          showDonorGrid={false}
        />
      )}
      <PatientEditWindow
        isOpo={sessionLocation?.isOpo}
        ageRanges={ageRanges}
        handleSubmit={handleSubmit}
        isEditMode={patientEditMode}
        patient={activePatient}
        show={showEditModal}
        onClosed={() => setShowAddWindow(false)}
        isRequestMode={false}
        isSubmitting={isSubmitting}
      />

      {sessionLocation?.id && isITransplantVisible && (
        <ITransplantModal
          mergeDonorData={iTransplantData}
          onUpdateDonor={handleITransplantUpdate}
          onAddDonor={handleITransplantAdd}
          onStatusChange={handleITransplantStatusChange}
          toggleITransplantDialog={toggleITransplantDialog}
          visible={isITransplantVisible}
          locationId={sessionLocation?.id}
          isOpo={sessionLocation?.isOpo}
        />
      )}
    </Page>
  );
});

PatientHome.displayName = 'PatientHome';

const StyledDataTable = styled(DataTable)`
  .k-grid-header .k-header {
    height: 36px;
  }

  .k-grid-header .k-header th {
    height: 36px;
    text-align: left;
  }
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stacks items vertically */
  gap: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.palette.white};
`;

const StyledActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.space.spacing50};
`;

const StyledRightSideActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${(props) => props.theme.space.spacing50};

  @media screen and (max-width: 585px) {
    gap: ${(props) => props.theme.space.spacing20};
  }
`;
