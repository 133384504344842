import React, { FunctionComponent, useEffect, useState } from 'react';

import { Spinner } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Page, PageHeader } from 'core/ui';

import { useAccessTokenSnapshot } from 'features/auth';
import { DocumentCategories, ExamStatus } from 'features/exam';
import { useExamFiles } from 'features/exam/hooks';

import { FileSelectSidebar } from './FileSelectSidebar';
import { FileViewer } from './FileViewer';

export const FileViewerPage: FunctionComponent = () => {
  const { examId } = useParams<{ examId: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const feature = queryParams.get('feature') || null;
  const status = queryParams.get('status') || ExamStatus.NEW.value;

  const { accessToken } = useAccessTokenSnapshot();
  const { files, currentFile, setCurrentFile } = useExamFiles(examId, status, accessToken);

  let filteredFiles = files;
  switch (feature) {
    case 'exams':
      filteredFiles = files.filter((file) => file.categoryId === DocumentCategories.EXAM.value || file.fileType === 'LINK');
      break;
    case 'attachments':
      filteredFiles = files.filter((file) => file.categoryId !== DocumentCategories.EXAM.value && file.fileType !== 'LINK');
      break;
    default:
      break;
  }

  return (
    <Page>
      <PageHeader title="File Viewer" />
      <StyledContainer>
        {filteredFiles && currentFile ? (
          <StyledViewerContainer>
            <FileViewer file={currentFile} overrideMultiMonitor />
          </StyledViewerContainer>
        ) : (
          <StyledViewerContainer>
            <StyledSpinnerContainer>
              <Spinner />
            </StyledSpinnerContainer>
          </StyledViewerContainer>
        )}

        <FileSelectSidebar
          files={filteredFiles}
          handleFileSelect={(file) => setCurrentFile(file)}
          selectedFile={currentFile}
          showDeleteBtn={false}
          showEditBtn={false}
          examId={examId?.toString()}
          hideUploadButton={true}
        />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 128px;
  grid-template rows: 1fr;
`;

const StyledViewerContainer = styled.div``;

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
