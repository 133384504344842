export const InitialValuesReadRequest = {
  id: 0,
  requestNotes: '',
  turnAroundTime: 0,
  locationType: '',
  dynamicFormFields: [],
  emailReportDestination: '',
};

export const isFormInEditModeConstant = true;

export enum AccordionKeys {
  PATIENT_ACCORDION_KEY = '1',
  EXAM_ACCORDION_KEY = '2',
  STUDY_ACCORDION_KEY = '3',
  COORDINATOR_KEY = '4',
}

export const EDIT_READ_REQUEST_ACTIVE_KEYS = [AccordionKeys.EXAM_ACCORDION_KEY, AccordionKeys.STUDY_ACCORDION_KEY, AccordionKeys.COORDINATOR_KEY];

export const ADD_READ_REQUEST_ACTIVE_KEYS = [
  AccordionKeys.PATIENT_ACCORDION_KEY,
  AccordionKeys.EXAM_ACCORDION_KEY,
  AccordionKeys.STUDY_ACCORDION_KEY,
  AccordionKeys.COORDINATOR_KEY,
];

export enum Branding {
  OTHER,
  COMPUMED,
  HEARTCOR,
  OPO,
  CORRECTIONS,
}
