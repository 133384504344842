import { find } from 'lodash';

import { ViewerModel } from 'models';

import { AliveCorExamTypes, DocumentCategories } from 'features/exam/constants';

import { FileTypes, Viewer } from '../constants';

const viewerIdToEnum = (viewerId: number | null | undefined, allViewers: ViewerModel[] | null | undefined): Viewer | null => {
  if (viewerId == null || allViewers == null) return null;

  const viewerName = allViewers.find((v) => v.id === viewerId)?.name;

  if (viewerName == null) {
    throw new Error(`Unable to find Viewer record that matches id: ${viewerId}.`);
  }

  const enumValue = find(Viewer, (v) => v.localeCompare(viewerName, 'en-US', { sensitivity: 'base' }) === 0);

  if (enumValue == null) {
    throw new Error(`Unable to match viewer with name: "${viewerName}" to Viewer enum.`);
  }

  return enumValue;
};

const isPDF = (fileType: string | null | undefined) => {
  return fileType == null ? false : FileTypes.PDF.includes(fileType);
};

const isImage = (fileType: string | null | undefined) => {
  return fileType == null ? false : FileTypes.IMAGE.includes(fileType);
};

const isXML = (fileType: string | null | undefined) => {
  return fileType == null ? false : FileTypes.XML.includes(fileType);
};

const isVideo = (fileType: string | null | undefined) => {
  return fileType == null ? false : FileTypes.VIDEO.includes(fileType.toUpperCase());
};

const isAliveCorSingleLead = (
  fileType: string | null | undefined,
  serviceDescription: string | null | undefined,
  fileCategoryId: number | null | undefined,
) => {
  return fileType == null || serviceDescription == null || fileCategoryId == null
    ? false
    : FileTypes.ALIVE_COR_ATC.includes(fileType) &&
        AliveCorExamTypes.SINGLE_LEAD.includes(serviceDescription) &&
        fileCategoryId === DocumentCategories.EXAM.value;
};

const isAliveCorSixLead = (fileType: string | null | undefined, serviceDescription: string | null | undefined, fileCategoryId: number | null | undefined) => {
  return fileType == null || serviceDescription == null || fileCategoryId == null
    ? false
    : isImage(fileType) && AliveCorExamTypes.SIX_LEAD.includes(serviceDescription) && fileCategoryId === DocumentCategories.EXAM.value;
};

export const FileTypeService = {
  viewerIdToEnum,
  isImage,
  isPDF,
  isXML,
  isAliveCorSingleLead,
  isAliveCorSixLead,
  isVideo,
};
