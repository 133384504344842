import { FunctionComponent, memo, useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { ExamForm } from './ExamForm';
import { HippaLogs } from 'features/share/fragments';
import { ShareGrid } from 'features/patient/fragments';
import { Button, Icon, TabButton, TabsContainer, Window, WindowActionsBar } from 'core/ui';
import { useEvent } from 'core/hooks';
import { ExamModel } from 'models';
import { apiClient } from 'features/api';
import { ExamFormService } from '../services';
import { NotificationsService } from 'core/notifications';
import { ExamFormValues } from '../types';
import { Spinner } from 'react-bootstrap';
import { SubmitHandler } from 'react-hook-form';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

export const ExamEditWindow: FunctionComponent<{
  examId: number;
  patientId: number;
  show: boolean;
  onExamFormSubmit: () => void;
  onDeleteShareClick: (studyShareId: number) => void;
  onClosed: () => void;
}> = memo(({ onClosed, examId, patientId, onExamFormSubmit, onDeleteShareClick }) => {
  const [tab, setTab] = useState<'EXAM_INFO' | 'HIPPA_LOGS' | 'SHARES_OUT'>('EXAM_INFO');
  const [exam, setExam] = useState<ExamModel>();
  const [examForm, setExamForm] = useState<ExamFormValues>();
  const formId = `${ExamForm.displayName}__${useId()}`;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchExam = useEvent(async () => {
    try {
      const exam = await apiClient.exams.getExamById(examId);
      setExamForm(ExamFormService.initializeExamFormValues(exam));
      setExam(exam);
    } catch (_) {
      NotificationsService.displayError('Failed to fetch exam');
    }
  });

  useEffect(() => {
    fetchExam();
  }, [examId, fetchExam]);

  const handleExamInfoClick = useEvent(() => setTab('EXAM_INFO'));
  const handleHippaLogClick = useEvent(() => setTab('HIPPA_LOGS'));
  const handleSharesOutClick = useEvent(() => setTab('SHARES_OUT'));

  const handleSubmit = useEvent<SubmitHandler<ExamFormValues>>(async (data) => {
    if (!exam) {
      throw new Error('Exam not found');
    }
    try {
      setIsSubmitting(true);
      const updatedExam = ExamFormService.mapFormValuesToExamModel(exam, data);
      await apiClient.exams.updateExam(updatedExam);
      NotificationsService.displaySuccess('Exam updated successfully');
      onExamFormSubmit();
    } catch (_) {
      NotificationsService.displayError('Failed to update exam');
    } finally {
      setIsSubmitting(false);
    }
  });

  const mainContent = () => {
    switch (tab) {
      case 'EXAM_INFO':
        return examForm ? <ExamForm formId={formId} examForm={examForm} setForm={setExamForm} exam={exam} onSubmit={handleSubmit} /> : <StyledSpinner />;
      case 'HIPPA_LOGS':
        return (
          <StyledTableContainer>
            <HippaLogs examId={examId} />
          </StyledTableContainer>
        );
      case 'SHARES_OUT':
        return (
          <StyledTableContainer>
            <ShareGrid patientId={patientId} examId={examId} onDeleteShareClick={onDeleteShareClick}></ShareGrid>
          </StyledTableContainer>
        );
    }
  };

  return (
    <Window modal title="Edit Exam" onClose={onClosed} initialHeight={460} initialWidth={475}>
      <TabsContainer>
        <TabButton type="button" selected={tab === 'EXAM_INFO'} roundedCorners="left" onClick={handleExamInfoClick}>
          Exams
        </TabButton>
        <TabButton type="button" selected={tab === 'HIPPA_LOGS'} roundedCorners="left" onClick={handleHippaLogClick}>
          HIPPA Logs
        </TabButton>
        <TabButton type="button" selected={tab === 'SHARES_OUT'} roundedCorners="right" onClick={handleSharesOutClick}>
          Shares Out
        </TabButton>
      </TabsContainer>
      <StyledMainContentContainer>{mainContent()}</StyledMainContentContainer>
      <WindowActionsBar>
        <Button form={formId} type="submit" disabled={isSubmitting} title="Save">
          {isSubmitting && <Icon icon={faSpinner} spin={isSubmitting}></Icon>}Save Exam
        </Button>
      </WindowActionsBar>
    </Window>
  );
});

ExamEditWindow.displayName = 'PatientEditWindow';

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  overflow: auto;
`;

const StyledTableContainer = styled.div`
  width: 95%;
  height: 100%;
`;

const StyledSpinner = styled(Spinner)`
  margin: auto;
`;
