// USE All Caps Snake Case e.g. WORD_WORD_WORD_...
export const MainRoutes = {
  AUTH: 'auth/*',
  EXAM: 'exam/*',
  GROUP: 'group/*',
  HOME: '/',
  HOME_2: '/home-2/*', // This is a duplicate of the HOME route that exists only so that we can have a react "home" page that coexists peacefully with the legacy portal.  The legacy portal already has a page called "/home".
  LOCATION: 'location/*',
  LOCATION_TYPE: 'location-type/*',
  PHYSICIAN: 'physician/*',
  PHYSICIAN_DASHBOARD: 'physician-dashboard/*',
  REQUEST: 'request/*',
  UNIT: 'unit/*',
  UPLOAD_TRACKER: 'upload-tracker/*',
  USER: 'user/*',
  ACCOUNT: 'account/*',
  UPLOAD_TOOL: 'upload/*',
  SHARE_EXAM: 'share-exam/*',
  PATIENT: 'patient-2/*',
  PACS: 'pacs/*',
  SERVICE: 'service/*',
  SEND_QUEUE: 'send-queue/*',
  /** Note: This intentionally has the "linkId" parameter even though none of the other top-level routes do.  Check the comments for the share routes feature and the authorization provider logic for more information. */
  SHARE: 'share/:linkId/*',
  DESTINATION: 'destination/*',
  SOURCE: 'source/*',
  DESTINATION_ROUTE: 'destination-route/*',
  LOCATIONFINALREPORT_TEMPLATE: 'location-finalreport-template/*',
  HL7SEND: 'hl7send/*',
  INTEGRATION: 'integration/*',
  FORM: '/form/*',
  TEMPLATE: 'template/*',
  READ_REQUEST: 'read-request/:examId/*',
  WORKLIST_VIEW_USER: 'worklist-view-user/*',
  SETTINGS: 'settings/*',
  QUERY_RETRIEVE: 'query-retrieve/*',
  FILE_VIEWER: 'file-viewer/:examId/*',
};
