import { useQuery } from '@tanstack/react-query';

import { ServiceModel } from 'models';

import { apiClient } from '../fragments/ApiClientProvider';
import { UseQueryReadResult } from '../types';

export function useQueryServices(): UseQueryReadResult<ServiceModel[], true> {
  const ageRangesQuery = useQuery({
    queryKey: ['services'],
    queryFn: apiClient.servicesClient.getAllServices,
    staleTime: Infinity,
  });

  return ageRangesQuery;
}
