import { memo, useMemo, useState } from 'react';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FieldSettings, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Filter, Window, WindowActionsBar } from 'core/ui';

import { FilterModalProps } from '../types';

export const FilterModal = memo<FilterModalProps>(({ columns, initialFilter, onClose, onSave }) => {
  const [filter, setFilter] = useState<CompositeFilterDescriptor | null>(initialFilter);

  const handleChange = useEvent((event: FilterChangeEvent) => {
    setFilter(event.filter);
  });

  const handleSaveClick = useEvent(() => {
    if (filter == null) {
      throw new Error('Filter cannot be null when saving.');
    }

    onSave(filter);
  });

  const fields: FieldSettings[] = useMemo(
    () =>
      columns
        .filter((column) => column.filter)
        .map((column) => ({
          name: column.field,
          label: column.title,
          filter: column.filter,
          operators: column?.operators ?? [],
        })),
    [columns],
  );

  if (filter == null) return null;

  return (
    <Window onClose={onClose} title="Filter" initialWidth={600}>
      <StyledDialogBodyDiv>
        <Filter fields={fields} onChange={handleChange} value={filter} />
      </StyledDialogBodyDiv>
      <WindowActionsBar>
        <Button onClick={handleSaveClick}>Apply Filter</Button>
      </WindowActionsBar>
    </Window>
  );
});

FilterModal.displayName = 'FilterModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
`;
