import { memo } from 'react';

import { PDFViewer as KendoPDFViewer } from '@progress/kendo-react-pdf-viewer';
import styled from 'styled-components';

import { PDFViewerProps } from '../types';

export const PDFViewer = memo<PDFViewerProps>(({ className, url }) => {
  return (
    <StyledComonentDiv className={className}>
      <KendoPDFViewer url={url} />
    </StyledComonentDiv>
  );
});

PDFViewer.displayName = 'PDFViewer';

const StyledComonentDiv = styled.div`
  .k-page {
    width: initial !important;
    height: initial !important;
  }

  .k-text-layer {
    width: initial !important;
    height: initial !important;
  }
`;
