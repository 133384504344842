import { FunctionComponent } from 'react';

import { faChevronsDown, faChevronsLeft, faChevronsRight, faChevronsUp } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import styled from 'styled-components';

import { BreakpointSelectors, Icon, PAGE_GRID_CLASSES, useBreakpoints } from 'core/ui';
import { hasText } from 'core/utils';

import { PatientDobLabel, PatientUtils, usePatientVerbiage } from 'features/patient';

import { ShareHeaderProps } from '../types';
import compumedLogo from './compumed_logo_blue.webp';

export const ShareHeader: FunctionComponent<ShareHeaderProps> = ({ className, patient, showSideBar, onShowSideBarToggleClick }) => {
  const patientVerbiage = usePatientVerbiage();
  const showUnosId = patientVerbiage === 'Donor' || hasText(patient?.unosID);
  const { desktop, landscape } = useBreakpoints();

  return (
    <StyledShareHeader className={cn(className, PAGE_GRID_CLASSES.Flush)}>
      {onShowSideBarToggleClick != null ? (
        <StyledSideBarButton type="button" onClick={onShowSideBarToggleClick}>
          {desktop || landscape ? (
            <Icon icon={showSideBar ? faChevronsLeft : faChevronsRight} fixedWidth />
          ) : (
            <Icon icon={showSideBar ? faChevronsDown : faChevronsUp} fixedWidth />
          )}
        </StyledSideBarButton>
      ) : (
        <div />
      )}

      <StyledInfoList>
        {desktop && (
          <>
            <StyledInfoContainer>
              <StyledInfoLabel>NAME:</StyledInfoLabel>
              <StyledInfoValue>{PatientUtils.formatName(patient?.firstName, patient?.lastName)}</StyledInfoValue>
            </StyledInfoContainer>

            {showUnosId && (
              <StyledInfoContainer>
                <StyledInfoLabel>UNOS ID:</StyledInfoLabel>
                <StyledInfoValue>{patient?.unosID}</StyledInfoValue>
              </StyledInfoContainer>
            )}

            <StyledInfoContainer>
              <StyledInfoLabel>MRN/{patientVerbiage.toUpperCase()} ID:</StyledInfoLabel>
              <StyledInfoValue>{patient?.patientNumber}</StyledInfoValue>
            </StyledInfoContainer>

            <StyledInfoContainer>
              <StyledInfoLabel>CASE ID:</StyledInfoLabel>
              <StyledInfoValue>{patient?.caseID}</StyledInfoValue>
            </StyledInfoContainer>

            <StyledInfoContainer>
              <StyledInfoLabel>DOB (AGE):</StyledInfoLabel>
              <StyledInfoValue>
                <PatientDobLabel dob={patient?.dob} showAge />
              </StyledInfoValue>
            </StyledInfoContainer>

            <StyledInfoContainer>
              <StyledInfoLabel>HOSPITAL:</StyledInfoLabel>
              <StyledInfoValue>{patient?.hospital}</StyledInfoValue>
            </StyledInfoContainer>
          </>
        )}
      </StyledInfoList>
      <StyledLogoContainer>
        <img src={compumedLogo} alt="CompuMed Logo" />
      </StyledLogoContainer>
    </StyledShareHeader>
  );
};

ShareHeader.displayName = 'ShareHeader';

const StyledShareHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: 0 12px 0 0;
  column-gap: 12px;
  overflow: hidden;
`;

const StyledSideBarButton = styled.button`
  all: unset;
  padding: 0 12px;
  align-self: stretch;
`;

const StyledInfoList = styled.div`
  flex: 1 1 0;
  display: flex;
  column-gap: ${({ theme }) => theme.space.spacing60};
  overflow: hidden;
  flex-wrap: wrap;
  height: 100%;

  ${BreakpointSelectors.Mobile} & {
    justify-content: end;
  }
`;

const StyledInfoContainer = styled.div`
  min-width: 125px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${BreakpointSelectors.Mobile} & {
    flex: 0 1 125px;
  }
`;

const StyledInfoLabel = styled.div`
  text-align: left;
  white-space: nowrap;
  min-height: 1lh;
  user-select: none;
`;

const StyledInfoValue = styled.div`
  text-align: left;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  min-height: 1lh;
`;

const StyledLogoContainer = styled.div`
  flex: 0 0 auto;
`;
