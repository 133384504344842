export const PAGE_GRID_TRACKS = {
  /** CSS grid column track that is flush against the navigation panel on the left. */
  FlushStart: 'page-flush-start',
  /** CSS grid column track that is flush against the viewport on the right. */
  FlushEnd: 'page-flush-end',
  /** CSS grid column track that provides standardized amount of padding on the left side. */
  ContentStart: 'page-content-start',
  /** CSS grid column track that provides standardized amount of padding on the right side. */
  ContentEnd: 'page-content-end',
};

export const PAGE_GRID_AREAS = {
  /** CSS grid area that will align an element flush against the left and right side of the application.  Primarily used on
   * the `<PageHeader />` component.  This should be applied to the CSS `grid-column` property like this:
   * ```css
   * grid-column: ${PAGE_GRID_AREAS.Flush};
   * ```
   */
  Flush: 'page-flush',
  /** CSS grid area that will align an element so that it has padding on the left and right sides.  Almost all direct children
   * of the `<Page />` element will use this.  This should be applied to the CSS `grid-column` property like this:
   * ```css
   * grid-column: ${PAGE_GRID_AREAS.Content};
   * ```
   *  */
  Content: 'page-content',
};

export const PAGE_GRID_CLASSES = {
  Flush: 'page-flush',
  Content: 'page-content',
};
