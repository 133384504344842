import { memo } from 'react';

import { faChevronDown, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { Avatar } from '@progress/kendo-react-layout';
import cn from 'classnames';
import _map from 'lodash/map';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LocationLiteModel } from 'models';

import { useBoolean, useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { useAuthentication } from 'features/auth/hooks/useAuthentication';
import { useCurrentUser } from 'features/auth/hooks/useCurrentUser';
import { useRouteGuard } from 'features/auth/hooks/useRouteGuard';
import { LOCAL_STORAGE_KEYS } from 'features/exam/constants';
import { useSessionLocation } from 'features/location/hooks/useSessionLocation';

import { DropdownButton } from '../DropdownButton';
import { DropdownNgChangeEvent, DropdownNgField } from '../DropdownNg';
import { Icon } from '../Icon';
import { PAGE_GRID_AREAS, PAGE_GRID_CLASSES } from '../Page/constants';
import { HelpModal } from './HelpModal';
import { PageHeaderProps } from './PageHeaderProps';
import compumedLogo from './assets/compumed_logo_blue.svg';

const MENU_ITEMS = {
  logout: {
    disabled: false,
    label: 'Logout',
    value: 'logout',
  },
};
const MENU_ITEMS_ARRAY = _map(MENU_ITEMS);
const ALL_LOCATIONS_OPTION = { id: null, name: 'All locations' };

type FormValuesType = { location: LocationLiteModel | null };

export const PageHeader = memo<PageHeaderProps>(({ className, title, showSessionLocation = false }) => {
  const { currentUser } = useCurrentUser();
  const { logoutOidc } = useAuthentication();
  const { sessionLocation, sessionLocationOptions, setSessionLocation } = useSessionLocation();
  const { requireSessionLocation } = useRouteGuard();

  const [isHelpModalOpen, { setTrue: openHelpModal, setFalse: closeHelpModal }] = useBoolean(false);

  const rhfContext = useForm<FormValuesType>({
    defaultValues: { location: sessionLocation },
  });

  const handleSessionLocationChange = useEvent((event: DropdownNgChangeEvent) => {
    setSessionLocation(event.value as LocationLiteModel | null);
  });

  const handleItemClick = useEvent((event: DropDownButtonItemClickEvent) => {
    if (event.item.value === MENU_ITEMS.logout.value) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.EXAM_GRID_STATE);
      logoutOidc();
    }
  });

  const initials = currentUser && currentUser !== 'anonymous' ? `${currentUser.firstName[0] ?? ''}${currentUser.lastName[0] ?? ''}` : '';

  return (
    <>
      <HelpModal show={isHelpModalOpen} onClose={closeHelpModal} />

      <FormProvider {...rhfContext}>
        <StyledComponentDiv className={cn(className, PAGE_GRID_CLASSES.Flush)}>
          <StyledLogoDiv>
            <StyledLogoImg src={compumedLogo} alt="CompuMed Logo" />
          </StyledLogoDiv>

          {hasText(title) && (
            <StyledBreadcrumbDiv>
              <StyledBreadcrumbItemDiv>{title}</StyledBreadcrumbItemDiv>
            </StyledBreadcrumbDiv>
          )}

          {showSessionLocation && sessionLocationOptions != null && (
            <StyledLocationDiv>
              <StyledDropdownNgField
                name="location"
                data={sessionLocationOptions}
                value={
                  sessionLocation == null && sessionLocationOptions != null && sessionLocationOptions.length > 1
                    ? ALL_LOCATIONS_OPTION
                    : sessionLocation == null && sessionLocationOptions != null && sessionLocationOptions.length == 1
                      ? sessionLocationOptions[0]
                      : (sessionLocation ?? null)
                }
                nullItem={requireSessionLocation && sessionLocation != null ? undefined : ALL_LOCATIONS_OPTION}
                textResolver={resolveLocationText as (dataItem: Record<string, unknown>) => string}
                filterDescriptorResolver={filterLocations}
                onChange={handleSessionLocationChange as (value: Record<string, unknown> | null) => void}
              />
            </StyledLocationDiv>
          )}

          {currentUser != null && currentUser !== 'anonymous' && (
            <StyledUserDiv>
              <StyledDropdownButton
                // imageUrl={ProfileIcon}
                items={MENU_ITEMS_ARRAY}
                onItemClick={handleItemClick}
                text={
                  <>
                    <StyledAvatar>{initials}</StyledAvatar>
                    <StyledDropdownNameContainer>{`${currentUser.firstName} ${currentUser.lastName}`}</StyledDropdownNameContainer>
                    <StyledIconContainer>
                      <StyledIcon icon={faChevronDown} width="10px" height="13px" />
                    </StyledIconContainer>
                  </>
                }
              />
            </StyledUserDiv>
          )}

          <StyledHelpDiv>
            <StyledHelpButton type="button" onClick={openHelpModal}>
              <StyledIcon icon={faCircleQuestion} width="25px" height="25px" transform="translateY(4px)" />
            </StyledHelpButton>
          </StyledHelpDiv>
        </StyledComponentDiv>
      </FormProvider>
    </>
  );
});

PageHeader.displayName = 'PageHeader';

function filterLocations(filterText: string): CompositeFilterDescriptor {
  return {
    logic: 'or',
    filters: [
      { field: 'code', operator: 'contains', value: filterText },
      { field: 'name', operator: 'contains', value: filterText },
    ],
  };
}

function resolveLocationText(location: LocationLiteModel): string {
  return location.id == null ? location.name : `${location.code} | ${location.name}`;
}

const StyledComponentDiv = styled.div`
  grid-column: ${PAGE_GRID_AREAS.Flush};
  display: grid;
  overflow: hidden;
  grid-template-columns: min-content 1fr min-content min-content min-content;
  grid-template-rows: 1fr;
  align-items: center;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.palette.white};
  padding: 0 0 1px ${({ theme }) => theme.space.spacing40};
`;

const StyledLogoDiv = styled.div`
  grid-column: 1 / span 1;
`;

const StyledLogoImg = styled.img`
  position: relative;
  object-fit: contain;
  height: 20px;
  top: 1px;
`;

const StyledBreadcrumbDiv = styled.div`
  grid-column: 2 / span 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: 16px;
  column-gap: 16px;

  &::before {
    content: '';
    display: block;
    flex: 0 0 1px;
    width: 1px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.palette.grayscale[6]};
  }
`;

const StyledBreadcrumbItemDiv = styled.div`
  flex: 0 0 min-content;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  white-space: nowrap;
  user-select: none;
`;

const StyledLocationDiv = styled.div`
  grid-column: 3 / span 1;
  margin-right: ${({ theme }) => theme.space.spacing40};

  && label {
    margin-bottom: 0;
  }
`;

const StyledDropdownNgField = styled(DropdownNgField)`
  width: 200px;
  align-self: end;
`;

const StyledUserDiv = styled.div`
  grid-column: 4 / span 1;
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledHelpDiv = styled.div`
  grid-column: 5 / span 1;
  margin-right: ${({ theme }) => theme.space.spacing20};
`;

const StyledDropdownButton = styled(DropdownButton)`
  flex: 0 0 min-content;
  height: 43px;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border: 0;
  & > .k-button {
    height: unset;
    padding: 0 ${({ theme }) => theme.space.spacing40};
  }
`;

const StyledHelpButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
  padding: 0 ${({ theme }) => theme.space.spacing20};
`;

const StyledDropdownNameContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledIconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 0.5em;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

const StyledIcon = styled(Icon)<{ width?: string; height?: string; transform?: string }>`
  && svg {
    width: ${({ width }) => width ?? 'auto'};
    height: ${({ height }) => height ?? 'auto'};
    transform: ${({ transform }) => transform ?? 'none'};
  }
`;
