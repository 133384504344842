import dayjs from 'dayjs';

import { PatientAgeRangeModel, PatientModel } from 'models';

import { unosRegex } from 'core/forms/services';
import { findOrThrow, hasText, trimForStorage } from 'core/utils';

import { PatientGenders } from '../constants';
import { PatientEditModalFormValues, PatientFormValues } from '../types';

function copyModelToForm(patient: PatientModel): PatientFormValues {
  return {
    id: patient.id,
    unosID: patient.unosID ?? '',
    active: patient.active,
    firstName: patient.firstName,
    lastName: patient.lastName,
    dob: patient.dob == null ? null : dayjs(patient.dob).toDate(),
    weight: patient.weight,
    height: patient.height,
    patientNumber: patient.patientNumber,
    caseID: patient.caseID ?? '',
    gender: patient.gender,
    crossClampDateTime: dayjs(patient.crossClampDateTime).toDate(),
    notes: patient.notes,
    hospital: patient.hospital,
    locationType: patient.locationType,
    ageRange: (patient as any).ageRange,
    location: patient.location,
    isUnosUnavailable: patient.unosID?.toLowerCase() === 'unknown',
    location_Id: patient.location_Id,
  };
}

function copyFormToModel({ dob, ...form }: PatientFormValues, originalModel: PatientModel): PatientModel {
  return {
    ...originalModel,
    ...form,
    dob: dob == null ? null : new Date(dob).toDateString(),
    crossClampDateTime: form.crossClampDateTime == null ? null : form.crossClampDateTime.toISOString(),
  };
}

const validateDonorForm = (patient: PatientModel | null | undefined) => {
  if (!patient) {
    return false;
  }
  let passesUnos;
  let passesCaseId;
  const unosId = patient?.unosID;
  if (unosId?.toLowerCase() === 'unknown') {
    passesUnos = true;
  } else {
    passesUnos = unosRegex.test(unosId ?? '');
  }
  const caseId = patient?.caseID;
  if (hasText(caseId)) {
    passesCaseId = true;
  }

  return passesUnos || passesCaseId;
};

function copyModelToEditModalForm(patient: PatientModel | null, allAgeRanges: PatientAgeRangeModel[]): PatientEditModalFormValues {
  if (patient == null) {
    return {
      unosID: '',
      active: true,
      firstName: '',
      lastName: '',
      dob: null,
      weight: null,
      height: null,
      patientNumber: '',
      caseID: '',
      gender: null,
      crossClampDateTime: null,
      notes: '',
      hospital: '',
      ageRange: null,
      isUnosUnavailable: false,
      location_Id: null,
    };
  }

  return {
    unosID: patient.unosID ?? '',
    active: patient.active,
    firstName: patient.firstName,
    lastName: patient.lastName,
    dob: patient.dob == null ? null : dayjs(patient.dob).toDate(),
    weight: patient.weight,
    height: patient.height,
    patientNumber: patient.patientNumber,
    caseID: patient.caseID ?? '',
    gender: PatientGenders[0],
    // gender:
    //   patient.gender == null
    //     ? null
    //     : findOrThrow(PatientGenders, (g) => equalsInsensitive(g.value, patient.gender), 'Could not find gender dropdown option for patient.'),
    crossClampDateTime: patient.crossClampDateTime == null ? null : new Date(patient.crossClampDateTime),
    notes: patient.notes ?? '',
    hospital: patient.hospital ?? '',
    ageRange:
      patient.ageRange_id == null ? null : findOrThrow(allAgeRanges, (r) => r.id === patient.ageRange_id, `Could not find age range entry for patient.`),
    isUnosUnavailable: patient.unosID?.toLowerCase() === 'unknown',
    location_Id: patient.location_Id,
  };
}

function copyEditModalFormToModel(form: PatientEditModalFormValues, originalModel: PatientModel | null): PatientModel {
  if (originalModel == null) {
    throw new Error('TODO: Implement this so that it can instantiate new patients.');
  }

  return {
    ...originalModel,
    unosID: trimForStorage(form.unosID),
    active: form.active,
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    dob: form.dob == null ? null : new Date(form.dob).toLocaleString(),
    weight: form.weight,
    height: form.height,
    patientNumber: form.patientNumber.trim(),
    caseID: form.caseID.trim(),
    gender: form.gender?.value ?? null,
    crossClampDateTime: form.crossClampDateTime == null ? null : new Date(form.crossClampDateTime).toLocaleString(),
    notes: trimForStorage(form.notes),
    hospital: trimForStorage(form.hospital),
    ageRange_id: form.ageRange?.id ?? null,
    location_Id: form.location_Id,
  };
}

async function initEditModalForm(getPatientPromise: Promise<PatientModel | null>, getAgeRangesPromise: Promise<PatientAgeRangeModel[]>) {
  const [patient, ageRanges] = await Promise.all([getPatientPromise, getAgeRangesPromise]);
  return PatientEditService.copyModelToEditModalForm(patient, ageRanges);
}

export const PatientEditService = {
  copyModelToForm,
  copyFormToModel,
  validateDonorForm,
  initEditModalForm,
  copyModelToEditModalForm,
  copyEditModalFormToModel,
};
