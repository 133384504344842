import { FocusEvent, memo, useCallback } from 'react';

import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useController } from 'react-hook-form';

import { DatePicker } from './DatePicker';
import { DatePickerFieldProps } from './DatePickerFieldProps';

export const DatePickerField = memo<DatePickerFieldProps>(
  ({ name, validator, onChange, onBlur, ...rest }) => {
    const {
      field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
      formState,
      fieldState: { isTouched, invalid, error },
    } = useController({ name, rules: { validate: validator } });

    const handleChange = useCallback(
      (event: DatePickerChangeEvent) => {
        rhfOnChange(event);
        onChange?.(event);
      },
      [onChange, rhfOnChange],
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLSpanElement | HTMLDivElement, Element>) => {
        rhfOnBlur();
        onBlur?.(event);
      },
      [onBlur, rhfOnBlur],
    );

    return (
      <DatePicker
        {...fieldRest}
        {...rest}
        valid={!invalid}
        visited={formState.isSubmitted || isTouched}
        validationMessage={error?.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  },
);

DatePickerField.displayName = 'DatePickerField';
