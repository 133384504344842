import { useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useEvent } from 'core/hooks';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, HeaderCell, Page, PageHeader, SwitchCell, TextCell, Toolbar } from 'core/ui';

import { UserActions, UserSelectors } from '../redux';

export const UserHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(UserSelectors.getAllGrid);
  const total = useSelector(UserSelectors.getTotalGrid);

  const gridPDFExport = useRef(null);
  const gridExcelExport = useRef(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleDataStateChange = (changeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  useEffect(() => {
    dispatch(UserActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        UserActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      gridExcelExport.current.save(res.payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/user/add');
  };

  const handleEditClick = useEvent((dataItem) => navigate(`/user/edit/${dataItem.id}`));

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-user',
        title: 'Edit User',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      sortable
      reorderable
      pageable={{ pageSizes: true }}
      total={total}
      data={users}
      {...dataState}
      onDataStateChange={handleDataStateChange}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn title="Action" filterable={false} sortable={false} headerCell={HeaderCell} cell={ActionListCell} width="80px" />
      <GridColumn cell={TextCell} field="firstName" filter="text" headerCell={HeaderCell} title="First Name" width="350px" />
      <GridColumn cell={TextCell} field="lastName" filter="text" headerCell={HeaderCell} title="Last Name" width="350px" />
      <GridColumn cell={TextCell} field="email" filter="text" headerCell={HeaderCell} title="Email" width="350px" />
      <GridColumn cell={TextCell} field="role" filter="text" headerCell={HeaderCell} title="Role" width="350px" />
      <GridColumn cell={SwitchCell} field="isApproved" filter="text" headerCell={HeaderCell} title="Approved" width="200px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Users" />
      <GridPDFExport data={users} ref={gridPDFExport}>
        {grid}
      </GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </Page>
  );
};
