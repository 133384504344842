import { FunctionComponent, useEffect, useState } from 'react';

import { Upload } from '@progress/kendo-react-upload';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { ApiRouteService } from 'core/api';
import { Button, ButtonVariants, Window, WindowActionsBar } from 'core/ui';

import { useApiClient } from 'features/api';
import { ITransplantModalProps } from '../types/ITransplantModalProps';

export const ITransplantModal: FunctionComponent<ITransplantModalProps> = ({
  onAddDonor,
  onUpdateDonor,
  onStatusChange,
  mergeDonorData,
  donorId,
  toggleITransplantDialog,
  visible,
  locationId,
  isOpo,
}) => {
  const [accessToken, setAccessToken] = useState('');
  const apiClient = useApiClient();
  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    const updateAccessToken = async () => {
      if (apiClient.httpClient.getAccessToken !== null) {
        setAccessToken(await apiClient.httpClient.getAccessToken());
      }
    };
    if (visible) {
      updateAccessToken();
    }
  }, [apiClient, visible]);

  const saveUrl = `${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/upload/compare?locationId=${locationId}&patientId=${donorId ?? null}`;
  const patientVerbiage = isOpo ? 'Donor' : 'Patient';
  return (
    <>
      <Window title="Upload iTransplant" onClose={toggleITransplantDialog} height={650} width={900}>
        <p>Please select your iTransplant XML file</p>
        <Upload
          batch={false}
          multiple={false}
          defaultFiles={[]}
          onStatusChange={onStatusChange}
          saveHeaders={{ Authorization: `Bearer ${accessToken}` }}
          withCredentials={false}
          saveUrl={saveUrl}
        />
        {mergeDonorData && (
          <>
            <StyledTable striped bordered hover>
              <StyledDivHeaderRow>
                <tr>
                  <th>Field</th>
                  {donorId && <th>Current Data</th>}
                  <th>Uploaded Data</th>
                </tr>
              </StyledDivHeaderRow>
              <tbody>
                <tr>
                  <td>UNOS ID</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.unosID}</td>}
                  <td>{mergeDonorData?.newPatient?.unosID}</td>
                </tr>
                <tr>
                  <td>LAST NAME</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.lastName}</td>}
                  <td>{mergeDonorData?.newPatient?.lastName}</td>
                </tr>
                <tr>
                  <td>FIRST NAME</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.firstName}</td>}
                  <td>{mergeDonorData?.newPatient?.firstName}</td>
                </tr>
                <tr>
                  <td>DOB</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.dob ? dayjs(mergeDonorData?.currentPatient?.dob).utc().format(dateFormat) : ''}</td>}
                  <td>{dayjs(mergeDonorData?.newPatient?.dob).utc().format(dateFormat)}</td>
                </tr>
                <tr>
                  <td>HEIGHT (cm)</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.height?.toFixed(2)}</td>}
                  <td>{mergeDonorData?.newPatient?.height?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>WEIGHT (kg)</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.weight?.toFixed(2)}</td>}
                  <td>{mergeDonorData?.newPatient?.weight?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GENDER</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.gender}</td>}
                  <td>{mergeDonorData?.newPatient?.gender}</td>
                </tr>
                <tr>
                  <td>MRN/PATIENT ID</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.patientNumber}</td>}
                  <td>{mergeDonorData?.newPatient?.patientNumber}</td>
                </tr>
                <tr>
                  <td>HOSPITAL</td>
                  {donorId && <td>{mergeDonorData?.currentPatient?.hospital}</td>}
                  <td>{mergeDonorData?.newPatient?.hospital}</td>
                </tr>
                <tr>
                  <td>CROSS CLAMP DATE</td>
                  {donorId && (
                    <td>
                      {mergeDonorData?.currentPatient?.crossClampDateTime && dayjs(mergeDonorData?.currentPatient?.crossClampDateTime).format(dateFormat)}
                    </td>
                  )}
                  <td>{mergeDonorData?.newPatient?.crossClampDateTime && dayjs(mergeDonorData?.newPatient?.crossClampDateTime).format(dateFormat)}</td>
                </tr>
              </tbody>
            </StyledTable>
            <StyledActionDiv>
              {!donorId && (
                <Button variant={ButtonVariants.SECONDARY} onClick={onAddDonor} iconClass="fa fa-plus">
                  {`Add ${patientVerbiage}`}
                </Button>
              )}
              {donorId && (
                <Button variant={ButtonVariants.SECONDARY} onClick={onUpdateDonor} iconClass="fa fa-sync">
                  {`Update ${patientVerbiage}`}
                </Button>
              )}
            </StyledActionDiv>
          </>
        )}
        <WindowActionsBar>
          <StyledDiv>
            <Button onClick={toggleITransplantDialog} variant={ButtonVariants.SECONDARY}>
              Close
            </Button>
          </StyledDiv>
        </WindowActionsBar>
      </Window>
    </>
  );
};

const StyledActionDiv = styled.div`
  display: flex;
  justify-content: start;
  gap: ${({ theme }) => theme.space.spacing50};
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledTable = styled(Table)`
  width: 100%;
  .td {
    word-wrap: break-word;
  }
  border-top: white;

  .table-bordered > :not(caption) > * > * {
    border-width: 0 0;
  }
  &&.table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: ${(props) => props.theme.colors.palette.grayscale[1]};
  }
  // increase size of rows
  td {
    padding: 8px;
  }
`;

const StyledDivHeaderRow = styled.thead`
  text-align: center;
  padding-top: 10px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.palette.grayscale[0]};
  font-size: 1rem;
  font-weight: 600;
  height: 35px;
  border-radius: 0;
  && svg {
    margin: 5px;
  }
`;
