import React, { FunctionComponent, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { styled, useTheme } from 'styled-components';
import Card from 'react-bootstrap/Card';
import { Icon } from 'core/ui';
import { faFileAlt, faFilePdf, faFileImage, faFileVideo } from '@fortawesome/pro-thin-svg-icons';
import { Placement } from 'react-bootstrap/types';

export const SupportedFiles: FunctionComponent<{ placement: Placement | undefined }> = ({ placement }) => {
  const theme = useTheme();
  const [isSupportedFilesOpen, setIsSupportedFilesOpen] = useState<boolean>(false);

  const onToggle = (isOpen: boolean) => {
    setIsSupportedFilesOpen(isOpen);
  };

  const supportedFilesPopover = (
    <StyledPopover id="supported-files">
      <StyledCard>
        <Card.Body>
          <StyledCardTitle>Supported File Formats</StyledCardTitle>
          <Card.Text>
            <FileType>
              <Icon icon={faFilePdf} color={theme.colors.palette.reds[5]} /> <strong>Documents:</strong> .pdf, .xml
            </FileType>
            <FileType>
              <Icon icon={faFileImage} color={theme.colors.palette.blues[5]} /> <strong>Images:</strong> .png, .jpg, .jpeg, .tiff, .szi, .svs
            </FileType>
            <FileType>
              <Icon icon={faFileVideo} color={theme.colors.palette.yellows[5]} /> <strong>Videos:</strong> .mp4, .mov
            </FileType>
            <FileType>
              <Icon icon={faFileAlt} color={theme.colors.palette.greens[5]} /> <strong>Others:</strong> .atc
            </FileType>
          </Card.Text>
        </Card.Body>
      </StyledCard>
    </StyledPopover>
  );

  return (
    <SupportedFilesContainer>
      <OverlayTrigger rootClose={true} trigger="click" placement={placement} overlay={supportedFilesPopover} onToggle={onToggle} show={isSupportedFilesOpen}>
        <KendoButton fillMode="link">Supported Files</KendoButton>
      </OverlayTrigger>
    </SupportedFilesContainer>
  );
};

const SupportedFilesContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  .k-button {
    padding: 0;
  }
  .k-button-text {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

const StyledPopover = styled(Popover)`
  z-index: 10002;
  width: 300px;
`;

const FileType = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const StyledCard = styled(Card)`
  font-size: ${({ theme }) => theme.fontSizes.body};
`;

const StyledCardTitle = styled(Card.Title)`
  margin-bottom: 10px;
`;
