import { useQuery } from '@tanstack/react-query';

import { ServiceModel } from 'models';

import { apiClient } from '../fragments/ApiClientProvider';
import { UseQueryReadResult } from '../types';

export function useQueryServicesByLocation(locationId: number, required?: true): UseQueryReadResult<ServiceModel[], true>;
export function useQueryServicesByLocation(locationId: number | null | undefined, required: false): UseQueryReadResult<ServiceModel[], false>;
export function useQueryServicesByLocation(locationId: number | null | undefined, required = true): UseQueryReadResult<ServiceModel[], false> {
  if (locationId == null && required) throw new Error('Cannot fetch services without a location Id when required was set to true.');

  return useQuery({
    queryKey: ['services', 'location', locationId],
    queryFn: () => (locationId == null ? Promise.resolve([]) : apiClient.servicesClient.getServicesByLocation(locationId)),
    staleTime: 1000 * 60 * 5,
  });
}
