import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { useAppDispatch, useAppSelector } from 'features/main/hooks';

import { InitialValues } from '../constants';
import { PhysicianActions, PhysicianSelectors } from '../redux';
import { PhysicianEditService } from '../services';
import { ContactTabContent } from './ContactTabContent';
import { CredentialsTabContent } from './CredentialsTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { IntegrationTabContent } from './IntegrationTabContent';
import { NotificationsTabContent } from './NotificationsTabContent';

const allTabKeys = ['general', 'contact', 'notifications', 'integration', 'credentials'];

export const PhysicianForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [generalForm, setGeneralForm] = useState(InitialValues.generalForm);
  const [contactForm, setContactForm] = useState(InitialValues.contactForm);
  const [credentialsForm, setCredentialsForm] = useState(InitialValues.credentialsForm);
  const [integrationForm, setIntegrationForm] = useState(InitialValues.integrationForm);
  const [notificationsForm, setNotificationsForm] = useState(InitialValues.notificationsForm);
  const [isFormReady, setIsFormReady] = useState(false);

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const physician = useAppSelector(PhysicianSelectors.getById(id));

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(PhysicianActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  useEffect(() => {
    (async () => {
      if (isFormInEditMode && physician != null) {
        const newForms = await PhysicianEditService.copyModelToForms(physician);

        setGeneralForm(newForms.generalForm);
        setContactForm(newForms.contactForm);
        setCredentialsForm(newForms.credentialsForm);
        setIntegrationForm(newForms.integrationForm);
        setNotificationsForm(newForms.notificationsForm);
        setIsFormReady(true);
      }
    })();
  }, [isFormInEditMode, physician]);

  useEffect(() => {
    if (!isFormInEditMode) {
      setIsFormReady(true);
    }
  }, [isFormInEditMode]);

  const handleSubmit = useCallback(async () => {
    const newPhysician = await PhysicianEditService.copyFormsToModel(physician, generalForm, contactForm, credentialsForm, integrationForm, notificationsForm);

    // eslint-disable-next-line no-useless-catch
    try {
      if (isFormInEditMode) {
        await dispatch(PhysicianActions.edit(newPhysician));
        NotificationsService.displaySuccess(`Physician Saved ${newPhysician.lastName}, ${newPhysician.firstName}.`);
      } else {
        await dispatch(PhysicianActions.add(newPhysician));
        NotificationsService.displaySuccess(`Physician Created ${newPhysician.lastName}, ${newPhysician.firstName}.`);
      }

      navigate('/physician');
    } catch (ex) {
      throw ex;
    }
  }, [physician, generalForm, contactForm, credentialsForm, integrationForm, notificationsForm, isFormInEditMode, navigate, dispatch]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!isFormReady) return <></>;

  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit Physician' : 'New Physician'} />
      <div>
        <TabContainer id="location-type-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="contact">Contact Info</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="notifications">Notifications</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="integration">Integration</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="credentials">Credentials</TabNavLink>
              </TabNavItem>
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent initialValues={generalForm} onSubmit={handleSubmit} onChange={setGeneralForm} />
            </TabPane>
            <TabPane eventKey="contact">
              <ContactTabContent initialValues={contactForm} onSubmit={handleSubmit} onChange={setContactForm} />
            </TabPane>
            <TabPane eventKey="notifications">
              <NotificationsTabContent initialValues={notificationsForm} onSubmit={handleSubmit} onChange={setNotificationsForm} />
            </TabPane>
            <TabPane eventKey="integration">
              <IntegrationTabContent initialValues={integrationForm} onSubmit={handleSubmit} onChange={setIntegrationForm} />
            </TabPane>
            <TabPane eventKey="credentials">
              <CredentialsTabContent initialValues={credentialsForm} onSubmit={handleSubmit} onChange={setCredentialsForm} />
            </TabPane>
          </TabContent>
        </TabContainer>
      </div>
    </Page>
  );
};

PhysicianForm.displayName = 'PhysicianForm';
