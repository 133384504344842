import { FunctionComponent, useCallback, useEffect, useState } from 'react';

// import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { StudySharePublicModel } from 'models';

import { FieldContainer, RhfValidators } from 'core/forms';
import { useEvent, useQueryParam, useValidatedParam } from 'core/hooks';
import { BreakpointSelectors, Button, Card, CheckboxField, InputField, MessageBox, PAGE_GRID_CLASSES, Page } from 'core/ui';
import { hasText } from 'core/utils';

import { useApiClient } from 'features/api';
import { useAuthentication } from 'features/auth';

import { ShareAuthenticationErrorCode } from '../constants';
import { ShareService } from '../services';
import compumedLogo from './compumed_logo_blue.webp';

const consentValidator = RhfValidators.requireValue(true, 'HIPAA agreement not accepted.');

type AuthenticateFormValues = {
  accessCode?: string;
  consent: boolean;
};

export const ShareLanding: FunctionComponent = () => {
  const linkId = useValidatedParam('linkId', 'guid', true);
  const apiClient = useApiClient();
  const { loginShare } = useAuthentication();
  const navigate = useNavigate();
  const returnRoute = useQueryParam('return', true);

  const rhfContext = useForm<AuthenticateFormValues>({
    defaultValues: { consent: false },
  });
  const { reset } = rhfContext;
  const [share, setShare] = useState<StudySharePublicModel | null | undefined>(); // Null indicates the share does not exist.  Undefined indicates we are still checking if the share exists.
  const [shareDate, setShareDate] = useState<Dayjs | null>(null);
  const [expiration, setExpiration] = useState<Dayjs | null>(null);
  const [expireDuration, setExpireDuration] = useState<Duration | null>(null);
  const [errors, setErrors] = useState<string[]>([]);

  const refreshExpireDuration = useCallback(() => {
    if (expiration != null) {
      const now = dayjs.utc().tz('America/Los_Angeles');
      const newDuration = dayjs.duration(expiration.diff(now));
      setExpireDuration(newDuration);
    } else {
      setExpireDuration(null);
    }
  }, [expiration]);

  const handleSubmit: SubmitHandler<AuthenticateFormValues> = useEvent(async (values) => {
    if (share == null) throw new Error('Submit cannot be called until the share information has been retrieved.');

    const result = await loginShare(linkId, typeof values.accessCode !== 'undefined' ? values.accessCode : null, values.consent);

    if (result.success) {
      if (hasText(returnRoute)) {
        navigate(returnRoute);
      } else {
        navigate(`/share/${linkId}/viewer`);
      }
    } else {
      let newErrorMessage: string;
      switch (result.errorCode) {
        case ShareAuthenticationErrorCode.HipaaConsentNotAccepted:
          newErrorMessage = 'HIPAA agreement not accepted.';
          break;
        case ShareAuthenticationErrorCode.ShareNotFound:
          newErrorMessage = 'Share was not found.';
          break;
        case ShareAuthenticationErrorCode.ShareExpired:
          newErrorMessage = 'Share has expired.';
          break;
        case ShareAuthenticationErrorCode.PasswordValidationFailed:
          newErrorMessage = 'Incorrect password.';
          break;
        default:
          newErrorMessage = 'Unable to login for share.';
      }
      setErrors([newErrorMessage]);
    }
  });

  useEffect(() => {
    (async () => {
      const newShare = await apiClient.studyShare.getStudySharePublic(linkId);

      if (newShare?.byPassSplashPage) {
        const result = await loginShare(linkId, null, true);
        if (result.success && hasText(returnRoute)) {
          navigate(returnRoute);
          return;
        }
      }

      const newShareDate: Dayjs | null = newShare?.dateCreated == null ? null : dayjs.tz(newShare.dateCreated, 'America/Los_Angeles');

      const newExpiration: Dayjs | null = newShare?.expireOn == null ? null : dayjs.tz(newShare.expireOn, 'America/Los_Angeles');

      reset(
        {
          ...(newShare?.hasPassword ? { accessCode: '' } : {}),
          consent: false,
        },
        { keepValues: false, keepDefaultValues: false },
      );
      setShare(newShare);
      setShareDate(newShareDate);
      setExpiration(newExpiration);
      setExpireDuration(null);
    })();
  }, [linkId, apiClient, reset]);

  useEffect(() => {
    // Have to set the expiration message immediately so that there isn't a delay for the first interval invocation.
    refreshExpireDuration();

    const intervalId = setInterval(() => {
      refreshExpireDuration();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshExpireDuration]);

  if (share === null) {
    // Share doesn't exist or is expired.
    return <Page>Share no longer exists.</Page>;
  } else if (typeof share === 'undefined') {
    // Still loading.
    return <Page />;
  }

  return (
    <Page>
      <FormProvider {...rhfContext}>
        <StyledShareHeader className={PAGE_GRID_CLASSES.Flush}>
          <StyledInfoList>
            {shareDate != null && (
              <StyledInfoContainer>
                <StyledInfoLabel>SHARE DATE:</StyledInfoLabel>
                <StyledInfoValue>{shareDate.format('YYYY-MM-DD HH:mm:ss')} PDT</StyledInfoValue>
              </StyledInfoContainer>
            )}

            {expireDuration != null && (
              <StyledInfoContainer>
                <StyledInfoLabel>EXPIRATION:</StyledInfoLabel>
                <StyledInfoValue>{ShareService.formatExpireMessage(expiration, expireDuration)}</StyledInfoValue>
              </StyledInfoContainer>
            )}
          </StyledInfoList>

          <StyledLogoContainer>
            <img src={compumedLogo} alt="CompuMed Logo" />
          </StyledLogoContainer>
        </StyledShareHeader>

        <StyledPageContent>
          <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
            <StyledCard>
              <h2>Welcome to CompuMed</h2>
              <hr />
              <p>Welcome to the CompuMed image sharing application.</p>

              <p>
                {share.hasPassword ? (
                  <>To continue, please enter your passphrase and read and accept the HIPAA Privacy Policy Notice:</>
                ) : (
                  <>To continue, please read and accept the HIPAA Privacy Policy Notice:</>
                )}
              </p>
            </StyledCard>

            <StyledCard>
              <h2>HIPAA AGREEMENT</h2>
              <hr />
              <p>PHI Usage Through the Use of CompuMed, Inc. Imaging Products</p>
              <p>
                This Health Insurance Portability and Accountability Act (HIPAA) Research Agreement (HIPAA Agreement) is made by and between you, the user, and
                CompuMed, Inc. Imaging, Inc.
              </p>
              <p>
                HIPAA sets forth a rule (Privacy Rule) governing the privacy of a patient&apos;s identifiable health information (referred to in the Privacy
                Rule as protected health information or PHI). The Privacy Rule sets forth guidelines intended to preserve the integrity and confidentiality of
                PHI. The Privacy Rule applies to health plans, health care clearinghouses and health care providers. The Privacy Rule can be found at 45 CFR,
                Part 164, Subpart E or at{' '}
                <a href="http://aspe.hhs.gov/admnsimp/final/pvctxt01.htm" rel="noopener noreferrer" target="_blank">
                  http://aspe.hhs.gov/admnsimp/final/pvctxt01.htm
                </a>
                .
              </p>
              <p>
                Your acceptance of this agreement acknowledges that you will follow The Privacy Rule as it relates to any PHI to which you have access through
                any CompuMed, Inc. Imaging products. Also, it acknowledges your understanding that the login and password provided to you should never be shared
                with anyone and that upon completion of using this image viewing application, you will log out of the system so that accidental access to PHI is
                not granted.
              </p>
            </StyledCard>

            <StyledCard>
              <h2>Access Share</h2>
              <hr />
              <StyledInputsContainer>
                {share.hasPassword && (
                  <StyledAccessCodeContainer>
                    <FieldContainer>
                      <InputField name="accessCode" type="password" required autoComplete={`share-access-code-${linkId}`} label="Access Code" />
                    </FieldContainer>
                  </StyledAccessCodeContainer>
                )}
                <FieldContainer $forCheckbox>
                  <CheckboxField
                    name="consent"
                    validator={consentValidator}
                    text="I acknowledge that I have read and agree to the above Terms and
              Conditions"
                  />
                </FieldContainer>
              </StyledInputsContainer>

              <hr />

              <StyledButtonsContainer>
                <Button type="submit">Proceed</Button>
              </StyledButtonsContainer>
            </StyledCard>

            {errors.length > 0 && (
              <div>
                {errors.map((e) => (
                  <MessageBox key={e} variant="error" message={e} />
                ))}
              </div>
            )}
          </StyledForm>
          <StyledFooter>© Copyright 2023 - 2024 CompuMed, Inc. - All rights reserved</StyledFooter>
        </StyledPageContent>
      </FormProvider>
    </Page>
  );
};

ShareLanding.displayName = 'ShareLanding';

const StyledPageContent = styled.div`
  grid-template-rows: min-content 1fr min-content;
`;

const StyledShareHeader = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: 0 12px;
  column-gap: 12px;
  overflow: hidden;

  ${BreakpointSelectors.Mobile} & {
    flex-direction: row-reverse;
  }
`;

const StyledLogoContainer = styled.div`
  flex: 0 0 auto;
`;

const StyledForm = styled.form`
  display: grid;
  row-gap: 16px;
  padding-top: 13px;
  grid-auto-rows: min-content;

  ${BreakpointSelectors.Desktop} & {
    justify-content: center;
    align-items: start;
    grid-template-columns: 500px;
  }

  ${BreakpointSelectors.Mobile} & {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled(Card)`
  padding: 0 0 12px 0;

  ${BreakpointSelectors.Mobile} & {
    border-radius: 0;
  }

  h2 {
    margin: 0;
    font-size: 20px;
    line-height: 32px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    user-select: none;
    padding: 6px 9px;
  }

  hr {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[5]};
    margin: 0 0 9px 0;
  }

  p {
    margin-bottom: 0;
    padding: 0 9px;
  }
  p + p {
    margin-top: 1lh;
  }
`;

const StyledInfoList = styled.div`
  flex: 1 1 0;
  display: flex;
  column-gap: ${({ theme }) => theme.space.spacing60};
  overflow: hidden;
  flex-wrap: wrap;
  height: 100%;

  ${BreakpointSelectors.Mobile} & {
    justify-content: end;
  }
`;

const StyledInfoContainer = styled.div`
  min-width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${BreakpointSelectors.Mobile} & {
    flex: 0 1 150px;
  }
`;

const StyledInfoLabel = styled.div`
  text-align: left;
  white-space: nowrap;
  min-height: 1lh;
`;

const StyledInfoValue = styled.div`
  text-align: left;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  min-height: 1lh;
`;

const StyledInputsContainer = styled.div`
  padding: 6px 9px 0 9px;
  display: grid;
  row-gap: 8px;
`;

const StyledAccessCodeContainer = styled.div`
  display: grid;
  align-items: center;
`;

const StyledButtonsContainer = styled.div`
  padding: 6px 9px;
`;

const StyledFooter = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
  user-select: none;
  text-align: center;
`;
