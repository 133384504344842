import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UploadFileStatus, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { ITransplantMergeModel } from 'models/ITransplantMergeModel';
import { NotificationsService } from 'core/notifications';
import { useApiClient } from 'features/api';
import { useSessionLocation } from 'features/location';

export const useITransplant = () => {
  const [iTransplantData, setITransplantData] = useState<ITransplantMergeModel | null>(null);
  const [isITransplantVisible, setIsITransplantVisible] = useState(false);
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { sessionLocation } = useSessionLocation(false);
  const patientVerbiage = sessionLocation?.isOpo ? 'Donor' : 'Patient';

  const toggleITransplantDialog = () => {
    setIsITransplantVisible((prev) => !prev);
    setITransplantData(null);
  };

  const handleITransplantStatusChange = (event: UploadOnStatusChangeEvent) => {
    const file = event.affectedFiles[0];
    if (file.status === UploadFileStatus.Uploaded) {
      const iTransplantModel = event.response?.response.result as ITransplantMergeModel;

      if (iTransplantModel.matchedPatientId) {
        NotificationsService.displaySuccess(`Found existing ${patientVerbiage} match`);
        navigate(`/patient-2/edit/${iTransplantModel.matchedPatientId}`);
        toggleITransplantDialog();
      } else {
        setITransplantData(iTransplantModel);
      }
    }
  };

  const handleITransplantAdd = async () => {
    if (!iTransplantData?.newPatient) return;

    try {
      const newPatientId = await apiClient.patientClient.createPatient(iTransplantData.newPatient);
      NotificationsService.displaySuccess(`${patientVerbiage} created successfully`);
      navigate(`/patient-2/edit/${newPatientId}`);
      toggleITransplantDialog();
    } catch {
      NotificationsService.displayError(`Error creating ${patientVerbiage}`);
    }
  };

  const handleITransplantUpdate = async () => {
    if (!iTransplantData?.currentPatient) {
      NotificationsService.displayWarning(`No ${patientVerbiage} match found to update`);
      return;
    }

    if (!iTransplantData?.newPatient) return;

    try {
      await apiClient.patientClient.updatePatient({
        ...iTransplantData.newPatient,
        id: iTransplantData.currentPatient.id,
      });
      NotificationsService.displaySuccess(`${patientVerbiage} updated successfully`);
      navigate(`/patient-2/edit/${iTransplantData.currentPatient.id}`);
      toggleITransplantDialog();
    } catch {
      NotificationsService.displayError(`Error updating ${patientVerbiage}`);
    }
  };

  return {
    iTransplantData,
    isITransplantVisible,
    toggleITransplantDialog,
    handleITransplantStatusChange,
    handleITransplantAdd,
    handleITransplantUpdate,
  };
};
