import { useMemo } from 'react';

/**
 * A custom hook to calculate initial window dimensions (width and height)
 * based on percentages of the viewport with optional min/max pixel constraints.
 *
 * @param widthPercentage - The desired width as a percentage (e.g., 60 for 60%).
 * @param heightPercentage - The desired height as a percentage (e.g., 80 for 80%).
 * @param options - Optional limits for dimensions (min/max values).
 * @returns An object containing `width` and `height` in pixels.
 */
export function usePercentageWindowSize(
  widthPercentage: number,
  heightPercentage: number,
  options?: {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
  },
) {
  const { minWidth, maxWidth, minHeight, maxHeight } = options || {};

  // Calculate the width and height once using `useMemo`
  const size = useMemo(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let width = Math.round((widthPercentage / 100) * viewportWidth);
    let height = Math.round((heightPercentage / 100) * viewportHeight);

    if (minWidth != null) {
      width = Math.max(width, minWidth);
    }
    if (maxWidth != null) {
      width = Math.min(width, maxWidth);
    }
    if (minHeight != null) {
      height = Math.max(height, minHeight);
    }
    if (maxHeight != null) {
      height = Math.min(height, maxHeight);
    }

    return { width, height };
  }, [widthPercentage, heightPercentage, minWidth, maxWidth, minHeight, maxHeight]);

  return size;
}
