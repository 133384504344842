import { memo, useMemo } from 'react';

import { GridFilterCellProps } from '@progress/kendo-react-grid';

import { ServiceModel } from 'models';

import { DropdownFilterCell } from 'core/ui';
import { hasText } from 'core/utils';

import { useQueryServices, useQueryServicesByLocation } from 'features/api';
import { useSessionLocation } from 'features/location';

const DEFAULT_SERVICE_SELECTION = { name: 'All', value: null };

export const ExamTypeFilterCell = memo<GridFilterCellProps>(({ value, ...restProps }) => {
  const { sessionLocation } = useSessionLocation(false);

  const { data: services } = useQueryServices();
  const { data: locationServices } = useQueryServicesByLocation(sessionLocation?.id, false);

  const servicesOptions = useMemo(() => {
    let filterServices: ServiceModel[];

    if (sessionLocation != null) {
      filterServices = [...(locationServices ?? [])];

      // Add the selected service if for some reason the service has not been explicitly assigned to the location.
      if (value !== null && filterServices.findIndex((s) => s.description === value) === -1) {
        const selectedService = services?.find((s) => s.description === value);
        if (selectedService != null) {
          filterServices.push(selectedService);
        }
      }
    } else {
      filterServices = services ?? [];
    }

    return filterServices.filter((s) => hasText(s.description)).map((s) => ({ name: s.description!, value: s.description! }));
  }, [locationServices, services, sessionLocation, value]);

  return <DropdownFilterCell {...restProps} value={value} dropdownData={servicesOptions} defaultItem={DEFAULT_SERVICE_SELECTION} />;
});

ExamTypeFilterCell.displayName = 'ExamTypeFilterCell';
