import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserModel, WorkListViewUserModel, WorklistViewAccessModel, WorklistViewModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { RHF_FULL_RESET } from 'core/forms/constants';
import { useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, DropdownField, Label, Page, PageHeader, SwitchField } from 'core/ui';

import { WorklistViewUserEditService } from '../services';
import { EditFormValues } from '../types';

export const WorklistViewUserForm: FunctionComponent = () => {
  const id = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();

  const [model, setModel] = useState<WorkListViewUserModel | null>(null);
  const [allUsers, setAllUsers] = useState<UserModel[] | null>(null);
  const [allAccessModes, setAllAccessModes] = useState<WorklistViewAccessModel[] | null>(null);
  const [allWorklists, setAllWorklists] = useState<WorklistViewModel[] | null>(null);

  const isInitializing = (model == null && id != null) || allUsers == null || allAccessModes == null || allWorklists == null;

  const rhfContext = useForm<EditFormValues>({
    defaultValues: {
      worklist: null,
      user: null,
      access: null,
      active: false,
      default: false,
    },
  });
  const { reset } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (data) => {
      const newModel = await WorklistViewUserEditService.save(data, model);
      setModel(newModel);
      NotificationsService.displaySuccess('Worklist access saved');
      navigate('/worklist-view-user');
    },
    [model, navigate],
  );

  const handleDeleteClick = useCallback(async () => {
    if (model == null) {
      throw new Error('Cannot delete access because the model is null or undefined.');
    }

    WorklistViewUserEditService.deleteAccess(model.id);
    NotificationsService.displaySuccess('Worklist access removed');
    navigate('/worklist-view-user');
  }, [model, navigate]);

  useEffect(() => {
    (async () => {
      const results = await WorklistViewUserEditService.initialize(id);

      setModel(results.model);
      setAllUsers(results.allUsers);
      setAllAccessModes(results.allAccessModes);
      setAllWorklists(results.allWorklists);

      reset(results.form, RHF_FULL_RESET);
    })();
  }, [id, reset]);

  if (isInitializing) return null;

  return (
    <FormProvider {...rhfContext}>
      <Page>
        <PageHeader title={id == null ? 'New Worklist Access Rule' : 'Edit Worklist Access Rule'} />
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>User</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField name="user" data={allUsers} dataItemKey="id" textField="email" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Worklist</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField id="worklist" name="worklist" data={allWorklists} dataItemKey="id" textField="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Access</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField name="access" data={allAccessModes} dataItemKey="id" textField="name" required filterable={false} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Default</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="default" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <StyledActionsColumn columnStart="1" columnEnd="span 2">
            <Button variant={ButtonVariants.SECONDARY} disabled={model == null} onClick={handleDeleteClick}>
              Delete
            </Button>
            <Button type="submit">Save</Button>
          </StyledActionsColumn>
        </StyledForm>
      </Page>
    </FormProvider>
  );
};

WorklistViewUserForm.displayName = 'WorklistViewUserForm';

const StyledForm = createStyledRhfForm('min-content 400px min-content');

const StyledActionsColumn = styled(GridColumn)`
  display: flex;
  justify-content: space-between;
`;
