import styled from 'styled-components';

import { BreakpointSelectors } from '../theme';
import { PAGE_GRID_AREAS, PAGE_GRID_CLASSES, PAGE_GRID_TRACKS } from './constants';

export const Page = styled.div`
  display: grid;
  // The following uses named grid areas to allow for easy and precise placement of child elements.
  // Here's a good overview of this technique: https://www.youtube.com/watch?v=cf-J4ffMBfo&t=67s
  grid-template-columns:
    [ ${PAGE_GRID_TRACKS.FlushStart}] ${({ theme }) => theme.space.spacing20}
    [ ${PAGE_GRID_TRACKS.ContentStart}] 1fr
    [ ${PAGE_GRID_TRACKS.ContentEnd}] ${({ theme }) => theme.space.spacing20}
    [ ${PAGE_GRID_TRACKS.FlushEnd}];
  grid-template-rows: min-content 1fr;
  row-gap: ${({ theme }) => theme.space.spacing10};
  overflow-x: auto;
  max-width: 100vw;
  ${BreakpointSelectors.VerticalClamp} & {
    overflow-y: hidden;
  }

  & > .${PAGE_GRID_CLASSES.Flush} {
    grid-column: ${PAGE_GRID_AREAS.Flush};
  }

  & > *:not(.${PAGE_GRID_CLASSES.Flush}),
  & > .${PAGE_GRID_CLASSES.Content} {
    grid-column: ${PAGE_GRID_AREAS.Content};
  }
`;
