import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import Iframe from 'react-iframe';

import { ViewerModel } from 'models';

import { useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { apiClient } from 'features/api';
import { AuthenticationScheme, useAccessTokenSnapshot, useAuthentication, useCurrentUser } from 'features/auth';
import { FileUrlService } from 'features/exam/services/file-url-service';

import { DEFAULT_MULTI_MONITOR_ENABLED, Viewer } from '../constants';
import { FileTypeService, FileViewerService } from '../services';
import { FileViewerProps } from '../types';
import { AliveCorSingleLeadViewer } from './AliveCorSingleLeadViewer';
import { AliveCorSixLeadViewer } from './AliveCorSixLeadViewer';
import { ImageViewer } from './ImageViewer';
import { PDFViewer } from './PDFViewer';
import { XMLViewer } from './XMLViewer';
import { VideoViewer } from './VideoViewer';

export const FileViewer: FunctionComponent<FileViewerProps> = ({ className, file, overrideMultiMonitor = false }) => {
  const { currentUser } = useCurrentUser();

  const popupRefs = useRef<Window[]>([]);
  const [allViewers, setAllViewers] = useState<ViewerModel[] | null>(null);
  const { accessToken, isAccessTokenReady } = useAccessTokenSnapshot();
  const [renderMode, setRenderMode] = useState<'inline' | 'popup' | null>(null);
  const { activeScheme } = useAuthentication();

  const safeUrls = useMemo(() => {
    if (!hasText(file.url)) return null;

    const splitUrls = file.url.split(';');
    return {
      primary: FileUrlService.getFileUrl(file.id, splitUrls[0], file.modified, accessToken),
      secondary: hasText(splitUrls[1]) ? FileUrlService.getFileUrl(file.id, splitUrls[1], file.modified, accessToken) : null,
      tertiary: hasText(splitUrls[2]) ? FileUrlService.getFileUrl(file.id, splitUrls[2], file.modified, accessToken) : null,
    };
  }, [file, accessToken]);

  const isMultiMonitorPreferred =
    (!overrideMultiMonitor && currentUser != null && typeof currentUser === 'object' ? currentUser.preferMultiMonitor : DEFAULT_MULTI_MONITOR_ENABLED) ??
    DEFAULT_MULTI_MONITOR_ENABLED;

  const viewer = FileTypeService.viewerIdToEnum(file.viewerId, allViewers);

  const handleBeforeUnload = useEvent(() => {
    if (popupRefs.current.length > 0) {
      popupRefs.current.forEach((w) => w.close());
      popupRefs.current = [];
    }
  });

  const initializePopups = useEvent(async () => {
    // Don't do anything if the page isn't ready yet.
    if (!isAccessTokenReady) return;

    // Close any existing popups before we start opening new ones.
    if (popupRefs.current.length > 0) {
      popupRefs.current.forEach((w) => w.close());
      popupRefs.current = [];
    }

    if (!isMultiMonitorPreferred) {
      setRenderMode('inline');
      return;
    }

    const newPopups = await FileViewerService.multiScreenPopup(FileUrlService.resolveFileUrl(file, accessToken));

    popupRefs.current = newPopups;
    setRenderMode(newPopups.length > 0 ? 'popup' : 'inline');
    window.addEventListener('beforeunload', handleBeforeUnload);
  });

  const cleanupPopups = useEvent(() => {
    if (popupRefs.current.length > 0) {
      popupRefs.current.forEach((w) => w.close());
      popupRefs.current = [];
    }

    window.removeEventListener('beforeunload', handleBeforeUnload);
  });

  // Fetch viewers.
  useEffect(() => {
    (async () => {
      const newViewers = await apiClient.viewerClient.getAllViewers(activeScheme === AuthenticationScheme.SHARE ? 'share-required' : 'msal-required');
      setAllViewers(newViewers);
    })();
  }, [activeScheme]);

  // Initialize popups.
  useEffect(() => {
    if (!isAccessTokenReady) return cleanupPopups;

    initializePopups();

    return cleanupPopups;
  }, [initializePopups, cleanupPopups, file, isMultiMonitorPreferred, isAccessTokenReady]);

  // Block rendering children until after async initialization tasks have completed.
  if (!isAccessTokenReady || renderMode !== 'inline' || allViewers == null || safeUrls == null) {
    return null;
  }

  if (viewer === Viewer.AliveCorSingleLead) {
    return <AliveCorSingleLeadViewer className={className} subFiles={file.subFiles} />;
  }

  if (viewer === Viewer.AliveCorSixLead) {
    return <AliveCorSixLeadViewer className={className} subFiles={file.subFiles} />;
  }

  if (FileTypeService.isPDF(file.fileType)) {
    return <PDFViewer className={className} url={`${FileUrlService.resolveFileUrl(file, accessToken)}#toolbar=0`} />;
  }

  if (FileTypeService.isImage(file.fileType)) {
    return <ImageViewer className={className} url={FileUrlService.resolveFileUrl(file, accessToken)} />;
  }

  if (FileTypeService.isXML(file.fileType)) {
    return <XMLViewer url={FileUrlService.resolveFileUrl(file, accessToken)} />;
  }

  if (FileTypeService.isVideo(file.fileType)) {
    return <VideoViewer file={file} accessToken={accessToken} />;
  }

  return (
    <Iframe
      className={className}
      url={FileUrlService.resolveFileUrl(file, accessToken)}
      width="100%"
      height="100%"
      allow="fullscreen; clipboard-read; clipboard-write"
    />
  );
};

FileViewer.displayName = 'FileViewer';
