//eslint-disable-next-line
export function getComplexErrorMessages(error: any): string {
  return error?.message
    ? error.message
    : error
      ? Object.values(error)
          .flatMap((e) => (e && typeof e === 'object' && 'message' in e ? [e.message] : []))
          .join(' ')
      : '';
}
