import { FunctionComponent, useContext } from 'react';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox } from '@progress/kendo-react-inputs';
import dayjs from 'dayjs';
import { Row, Spinner, Table } from 'react-bootstrap';
import styled from 'styled-components';

import { Icon } from 'core/ui';

import { MergePatientValueSelectContext } from '../contexts';
import { MergeStepDonorConfirmProps } from '../types';
import { MergePatientValueSelect } from './MergePatientValueSelect';

export const MergeStepDonorConfirm: FunctionComponent<MergeStepDonorConfirmProps> = ({
  srcSelectAll,
  destSelectAll,
  selectedFields,
  dateFormat,
  onSelectAll,
}) => {
  const mergePatientContext = useContext(MergePatientValueSelectContext);
  return (
    <>
      {mergePatientContext.srcPatient && mergePatientContext.destPatient ? (
        <StyledConfirmDonorDiv>
          <StyledDivHeaderRow>
            Source
            <svg xmlns="http://www.w3.org/2000/svg" width="544" height="6" viewBox="0 0 544 6" fill="white">
              <path d="M544 3L539 0.113249V5.88675L544 3ZM0 3.5H539.5V2.5H0V3.5Z" fill="white" />
            </svg>
            Destination
          </StyledDivHeaderRow>
          <StyledDivContentRow>
            <StyledTable striped bordered hover>
              <thead>
                <tr>
                  <StyledTableHeader></StyledTableHeader>
                  <StyledTableHeader>
                    <PatientIcon />
                    {mergePatientContext.srcPatient?.firstName} {mergePatientContext.srcPatient?.lastName}
                  </StyledTableHeader>
                  <StyledTableHeader>
                    <PatientIcon />
                    {mergePatientContext.destPatient?.firstName} {mergePatientContext.destPatient?.lastName}
                  </StyledTableHeader>
                  <StyledTableFinalHeader>
                    <StyleArrow>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                        <path
                          d="M19.9999 7.9989C19.998 7.8784 19.9689 7.75988 19.9148 7.6522C19.8607 7.54452 19.783 7.45045 19.6874 7.37702C19.6859 7.37597 19.6843 7.37493 19.6827 7.3739L15.7546 3.44577C15.6809 3.36899 15.5926 3.30769 15.4949 3.26547C15.3972 3.22324 15.2921 3.20093 15.1856 3.19985C15.0792 3.19876 14.9736 3.21893 14.8751 3.25916C14.7765 3.29939 14.687 3.35888 14.6117 3.43414C14.5365 3.50941 14.477 3.59893 14.4368 3.69747C14.3965 3.79601 14.3764 3.90159 14.3774 4.00802C14.3785 4.11446 14.4008 4.2196 14.4431 4.3173C14.4853 4.41501 14.5466 4.5033 14.6234 4.57702L17.2577 7.21139L8.78899 7.21139C5.24446 7.21139 2.38899 4.35593 2.38899 0.811394C2.39049 0.705384 2.3709 0.600134 2.33138 0.501759C2.29184 0.403384 2.23315 0.313848 2.15872 0.238352C2.08428 0.162856 1.99559 0.102907 1.89778 0.0619888C1.79997 0.0210706 1.69501 -8.00136e-07 1.58899 -8.04771e-07C1.48297 -8.09405e-07 1.37801 0.0210706 1.2802 0.0619888C1.1824 0.102907 1.0937 0.162856 1.01926 0.238352C0.944828 0.313848 0.886141 0.403384 0.846609 0.501759C0.807077 0.600134 0.787493 0.705384 0.788992 0.811393C0.788992 5.22046 4.37992 8.81139 8.78899 8.8114L17.2577 8.8114L14.6234 11.4458C14.5466 11.5195 14.4853 11.6078 14.4431 11.7055C14.4008 11.8032 14.3785 11.9083 14.3774 12.0148C14.3764 12.1212 14.3965 12.2268 14.4368 12.3253C14.477 12.4239 14.5365 12.5134 14.6117 12.5886C14.687 12.6639 14.7765 12.7234 14.8751 12.7636C14.9736 12.8039 15.0792 12.824 15.1856 12.8229C15.2921 12.8219 15.3972 12.7996 15.4949 12.7573C15.5926 12.7151 15.6809 12.6538 15.7546 12.577L19.6874 8.64421C19.7861 8.56837 19.8658 8.47054 19.92 8.35852C19.9743 8.24649 20.0016 8.12335 19.9999 7.9989ZM5.41087 9.78639C4.83807 9.57039 4.29245 9.30163 3.77805 8.98483C1.95725 10.4528 0.788991 12.6962 0.788991 15.2114C0.788991 15.653 1.14739 16.0114 1.58899 16.0114C2.03059 16.0114 2.38899 15.653 2.38899 15.2114C2.38899 12.9234 3.60047 10.9184 5.41087 9.78639Z"
                          fill="#2666a6"
                        />
                      </svg>
                    </StyleArrow>
                    {selectedFields?.firstName} {selectedFields?.lastName}
                  </StyledTableFinalHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <StyledCheckbox value={srcSelectAll} onChange={(e) => onSelectAll(e, 'src')} label="Select All" />
                  </td>
                  <td colSpan={4}>
                    <StyledCheckbox value={destSelectAll} onChange={(e) => onSelectAll(e, 'dest')} label="Select All" />
                  </td>
                </tr>
                <tr>
                  <StyledTableData>UNOS ID</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="unosID"
                    fieldValue={mergePatientContext.srcPatient?.unosID}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('unosID')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="unosID"
                    fieldValue={mergePatientContext.destPatient?.unosID}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('unosID')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>

                  <td colSpan={2}>{selectedFields?.unosID}</td>
                </tr>
                <tr>
                  <StyledTableData>MRN</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="patientNumber"
                    fieldValue={mergePatientContext.srcPatient?.patientNumber}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('patientNumber')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="patientNumber"
                    fieldValue={mergePatientContext.destPatient?.patientNumber}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('patientNumber')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.patientNumber}</td>
                </tr>
                <tr>
                  <StyledTableData>LAST NAME</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="lastName"
                    fieldValue={mergePatientContext.srcPatient?.lastName}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('lastName')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="lastName"
                    fieldValue={mergePatientContext.destPatient?.lastName}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('lastName')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>

                  <td colSpan={2}>{selectedFields?.lastName}</td>
                </tr>
                <tr>
                  <StyledTableData>FIRST NAME</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="firstName"
                    fieldValue={mergePatientContext.srcPatient?.firstName}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('firstName')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="firstName"
                    fieldValue={mergePatientContext.destPatient?.firstName}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('firstName')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>

                  <td colSpan={2}>{selectedFields?.firstName}</td>
                </tr>
                <tr>
                  <StyledTableData>DOB</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="dob"
                    fieldValue={dayjs(mergePatientContext.srcPatient?.dob).utc().format(dateFormat)}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('dob')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="dob"
                    fieldValue={dayjs(mergePatientContext.destPatient?.dob).utc().format(dateFormat)}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('dob')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{dayjs(selectedFields?.dob).utc().format(dateFormat)}</td>
                </tr>
                <tr>
                  <StyledTableData>GENDER</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="gender"
                    fieldValue={mergePatientContext.srcPatient?.gender}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('gender')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="gender"
                    fieldValue={mergePatientContext.destPatient?.gender}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('gender')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.gender}</td>
                </tr>
                <tr>
                  <StyledTableData>Case ID</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="caseID"
                    fieldValue={mergePatientContext.srcPatient?.caseID}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('caseID')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="caseID"
                    fieldValue={mergePatientContext.destPatient?.caseID}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('caseID')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.caseID}</td>
                </tr>
                <tr>
                  <StyledTableData>HEIGHT (cm)</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="height"
                    fieldValue={mergePatientContext.srcPatient?.height}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('height')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="height"
                    fieldValue={mergePatientContext.destPatient?.height}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('height')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.height}</td>
                </tr>
                <tr>
                  <StyledTableData>WEIGHT (kg)</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="weight"
                    fieldValue={mergePatientContext.srcPatient?.weight}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('weight')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="weight"
                    fieldValue={mergePatientContext.destPatient?.weight}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('weight')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.weight}</td>
                </tr>
                <tr>
                  <StyledTableData>Hospital</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="hospital"
                    fieldValue={mergePatientContext.srcPatient?.hospital}
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('hospital')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="hospital"
                    fieldValue={mergePatientContext.destPatient?.hospital}
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('hospital')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields?.hospital}</td>
                </tr>
                <tr>
                  <StyledTableData>Cross Clamp Time</StyledTableData>
                  <MergePatientValueSelect
                    fieldName="crossClampDateTime"
                    fieldValue={
                      mergePatientContext.srcPatient?.crossClampDateTime && dayjs(mergePatientContext.srcPatient?.crossClampDateTime).utc().format(dateFormat)
                    }
                    isSelected={Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes('crossClampDateTime')}
                    sourceOrDest="src"
                  ></MergePatientValueSelect>
                  <MergePatientValueSelect
                    fieldName="crossClampDateTime"
                    fieldValue={
                      mergePatientContext.destPatient?.crossClampDateTime && dayjs(mergePatientContext.destPatient?.crossClampDateTime).utc().format(dateFormat)
                    }
                    isSelected={Object.keys(mergePatientContext.selectedDestFields ?? {}).includes('crossClampDateTime')}
                    sourceOrDest="dest"
                  ></MergePatientValueSelect>
                  <td colSpan={2}>{selectedFields.crossClampDateTime && dayjs(selectedFields?.crossClampDateTime).utc().format(dateFormat)}</td>
                </tr>
              </tbody>
            </StyledTable>
          </StyledDivContentRow>
        </StyledConfirmDonorDiv>
      ) : (
        <StyledCenteredSpinner animation="border" />
      )}
    </>
  );
};

const PatientIcon: FunctionComponent = () => {
  return <StyledIcon fixedWidth={false} icon={faUser} />;
};

MergeStepDonorConfirm.displayName = 'MergeStepDonorConfirm';

const StyledConfirmDonorDiv = styled.div`
  overflow: auto;
}
`;

const StyledTable = styled(Table)`
  width: 100%;
  .td {
    word-wrap: break-word;
  }
  border-top: white;

  .table-bordered > :not(caption) > * > * {
    border-width: 0 0;
  }
  &&.table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: ${(props) => props.theme.colors.palette.grayscale[1]};
  }
  // increase size of rows
  td {
    padding: 8px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding-right: ${(props) => props.theme.space.spacing20};
`;

const StyledTableHeader = styled.th`
  font-style: normal;
  font-weight: 600;
`;

const StyledTableData = styled.td`
  text-align: right;
`;

const StyledDivHeaderRow = styled.div`
  text-align: center;
  padding-top: 10px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.palette.grayscale[0]};
  font-size: 1rem;
  font-weight: 600;
  height: 35px;
  border-radius: 0;
  && svg {
    margin: 5px;
  }
`;

const StyledDivContentRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;

const StyledTableFinalHeader = styled.div`
  color: black;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const StyleArrow = styled.span`
  margin-right: 5px;
`;

const StyledCenteredSpinner = styled(Spinner)`
  margin: auto;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
`;
