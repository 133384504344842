import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LocationTypeModel } from 'models';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { useAppDispatch, useAppSelector } from 'features/main/hooks';

import { LocationTypeActions, LocationTypeSelectors } from '../redux';
import { LocationTypeInitialValueService } from '../services';
import { GeneralTabFormValues, NotificationsTabFormValues } from '../types';
import { GeneralTabContent } from './GeneralTabContent';
import { NotificationsTabContent } from './NotificationsTabContent';

const allTabKeys = ['general', 'notifications'];

export const LocationTypeForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [generalTabForm, setGeneralTabForm] = useState<GeneralTabFormValues>({
    name: '',
    active: false,
  });
  const [notificationsTabForm, setNotificationsTabForm] = useState<NotificationsTabFormValues>({
    examUploadWarning: '',
    examCompleteNotification: false,
    emailNotificationSTATExam: false,
    textNotificationSTATExam: false,
  });
  const [initialState, setInitialState] = useState<LocationTypeModel>();

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const locationType = useAppSelector(LocationTypeSelectors.getById(id));

  useEffect(() => {
    const newInitialState = isFormInEditMode ? locationType : LocationTypeInitialValueService.getInitialValues();
    setGeneralTabForm({
      name: newInitialState?.name ?? '',
      active: newInitialState?.active ?? false,
    });
    setNotificationsTabForm({
      examUploadWarning: newInitialState?.examUploadWarning ?? '',
      examCompleteNotification: newInitialState?.examCompleteNotification ?? false,
      emailNotificationSTATExam: newInitialState?.emailNotificationSTATExam ?? false,
      textNotificationSTATExam: newInitialState?.textNotificationSTATExam ?? false,
    });
    setInitialState(newInitialState);
  }, [locationType, isFormInEditMode]);

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(LocationTypeActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  const handleSubmit = useCallback(async () => {
    if (!initialState) {
      throw new Error('Cannot update an undefined or null location type.');
    }

    const update: LocationTypeModel = {
      ...(initialState as LocationTypeModel),
      ...generalTabForm,
      ...notificationsTabForm,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      if (isFormInEditMode) {
        await dispatch(LocationTypeActions.edit(update));
        NotificationsService.displaySuccess(`Saved ${update.name}.`);
      } else {
        await dispatch(LocationTypeActions.add(update));
        NotificationsService.displaySuccess(`Created ${update.name}.`);
      }
      navigate('/location-type');
    } catch (ex) {
      throw ex;
    }
  }, [dispatch, generalTabForm, initialState, isFormInEditMode, navigate, notificationsTabForm]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!initialState) return <></>;

  return (
    initialState && (
      <Page>
        <PageHeader title={isFormInEditMode ? 'Edit Location Type' : 'New Location Type'} />
        <div>
          <TabContainer id="location-type-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
            <TabNavContainer>
              <TabNav>
                <TabNavItem>
                  <TabNavLink eventKey="general">General</TabNavLink>
                </TabNavItem>
                <TabNavItem>
                  <TabNavLink eventKey="notifications">Notifications</TabNavLink>
                </TabNavItem>
              </TabNav>
            </TabNavContainer>
            <TabContent>
              <TabPane eventKey="general">
                <GeneralTabContent initialValues={generalTabForm} onSubmit={handleSubmit} onChange={setGeneralTabForm} />
              </TabPane>
              <TabPane eventKey="notifications">
                <NotificationsTabContent initialValues={notificationsTabForm} onSubmit={handleSubmit} onChange={setNotificationsTabForm} />
              </TabPane>
            </TabContent>
          </TabContainer>
        </div>
      </Page>
    )
  );
};

LocationTypeForm.displayName = 'LocationTypeForm';
