import { useEffect, useMemo, useState } from 'react';

import { faDownload, faXRay } from '@fortawesome/pro-solid-svg-icons';
import { State } from '@progress/kendo-data-query';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridColumn, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { MenuSelectEvent } from '@progress/kendo-react-layout';
import { Offset } from '@progress/kendo-react-popup';
import styled from 'styled-components';

import { UploadModel } from 'models';

import { DataResult } from 'core/api';
import { useAsyncCallback, useBoolean, useDebounceEmitter, useEvent, useInterval, useOutsideClick } from 'core/hooks';
import { ActionListCell, DataTable, HeaderCell, Page, PageHeader, Popup, Toolbar } from 'core/ui';
import { Action, Button, ButtonVariants, DEFAULT_PAGE_SIZES } from 'core/ui';
import { downloadBlobAsFile } from 'core/utils';

import { apiClient } from 'features/api';
import { FilterModal } from 'features/exam/fragments/FilterModal';
import { ContextMenuItems } from 'features/upload-tracker/constants';
import { UploadTrackerService } from 'features/upload-tracker/services';

import { GatewayLogModal } from './GatewayLogModal';

const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };
const AUTO_REFRESH_INTERVAL = 60 * 1000; // 60 sec
const DEFAULT_EMPTY_FILTER: CompositeFilterDescriptor = { filters: [], logic: 'and' };
const DEFAULT_CONTEXT_MENU_OFFSET: Offset = { left: 0, top: 0 };

export const UploadTrackerHome = () => {
  const [isFilterModalOpen, { toggle: toggleIsFilterModalOpen, setFalse: closeFilterModal }] = useBoolean(false);

  //TODO: use async callback
  const [isLoading, setIsLoading] = useState(false);
  const [isContextMenuOpen, { setTrue: openContextMenu, setFalse: closeContextMenu }] = useBoolean(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<Offset>(DEFAULT_CONTEXT_MENU_OFFSET);
  const [isGatewayLogsOpen, { setTrue: openGatewayLogs, setFalse: closeGatewayLogs }] = useBoolean(false);
  const [contextMenuUpload, setContextMenuUpload] = useState<UploadModel | null>(null);
  const [uploads, setUploads] = useState<null | DataResult<UploadModel>>(null);
  const [dataState, setDataState] = useState<State>({
    ...{
      skip: 0,
      take: 100,
    },
    sort: [{ field: 'id', dir: 'desc' }],
  });

  const [fetchUploads] = useAsyncCallback(async (signal, showLoadingSpinner: boolean, dataStateOverride?: State) => {
    if (dataStateOverride == null && dataState == null) {
      throw new Error('Cannot fetch exams because the dataState is null and a dataStateOverride was not specified.');
    }

    try {
      if (showLoadingSpinner) {
        setIsLoading(true);
      }

      const newUploads = await apiClient.uploadTrackerClient.getAllForKendoGrid(dataStateOverride ?? dataState, signal);
      setUploads(newUploads);
    } finally {
      if (showLoadingSpinner) {
        setIsLoading(false);
      }
    }
  });

  const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
    let isEventFromPager = false;
    let isEventFromSortClick = false;

    // Determine if the event originates within the pager.
    if (event.syntheticEvent.target instanceof Element) {
      isEventFromPager = event.syntheticEvent.target.closest('.k-pager') != null;
    }

    // Determine if the event is the result of the user clicking a sortable header cell.
    if (event.syntheticEvent.type === 'click' && event.syntheticEvent.target instanceof Element) {
      // We have to make sure we are within the separate <table> element for the headers, but we also have to make sure we aren't in the filter row.
      isEventFromSortClick =
        event.syntheticEvent.target.closest('.k-grid-header-table') != null && event.syntheticEvent.target.closest('.k-filter-row') == null;
    }

    setDataState(event.dataState);

    if (isEventFromPager || isEventFromSortClick) {
      clearDebounce();
      fetchUploads(true, event.dataState);
    } else {
      emitDebounce();
    }
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchUploads(true);
  }, 500);

  const initialize = useEvent(async () => {
    fetchUploads(true);
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  useInterval(() => {
    clearDebounce();
    fetchUploads(false);
  }, AUTO_REFRESH_INTERVAL);

  const handleExportCSVClick = useEvent(async () => {
    if (dataState == null) {
      throw new Error('Cannot export CSV because the dataState is null.');
    }

    const csvBlob = await apiClient.uploadTrackerClient.exportForKendoGrid(dataState);
    downloadBlobAsFile(csvBlob, 'csv', 'Uploads');
  });

  const handleDownloadClick = useEvent(async (file: UploadModel) => {
    const downloadBlob = await apiClient.uploadTrackerClient.downloadFile(file.studyInstanceUID);
    downloadBlobAsFile(downloadBlob, 'zip', file.studyInstanceUID);
  });

  const handleExamClick = useEvent((file: UploadModel) => {
    window.open(`/exam/${file.examId}/edit`, '_blank');
  });

  const gridActions: Action[] = useMemo(() => {
    return [
      {
        key: 'download',
        title: 'Download',
        icon: faDownload,
        onClick: (_, dataItem) => handleDownloadClick(dataItem),
      },
      {
        key: 'exam',
        title: 'Exam',
        icon: faXRay,
        disabled: (dataItem: UploadModel) => !dataItem.examId,
        onClick: (_, dataItem) => handleExamClick(dataItem),
      },
    ];
  }, [handleDownloadClick, handleExamClick]);

  const handleFilterModalSave = useEvent((filter: CompositeFilterDescriptor) => {
    closeFilterModal();

    const filteredDataState = { ...dataState, filter };
    clearDebounce();
    fetchUploads(true, filteredDataState);
    setDataState(filteredDataState);
  });

  const contextMenuRef = useOutsideClick<HTMLDivElement>(() => {
    if (isContextMenuOpen) {
      setContextMenuUpload(null);
      setContextMenuOffset(DEFAULT_CONTEXT_MENU_OFFSET);
      closeContextMenu();
    }
  });

  const handleContextMenuOpen = useEvent((event: MouseEvent, dataItem: UploadModel) => {
    setContextMenuUpload(dataItem);
    setContextMenuOffset({ left: event.clientX, top: event.clientY });
    openContextMenu();
  });

  const handleOnContextMenuSelect = useEvent((event: MenuSelectEvent) => {
    if (event.item.text === ContextMenuItems.GatewayLogs.text) {
      openGatewayLogs();
    }

    closeContextMenu();
  });

  const grid = (
    <>
      <DataTable
        {...dataState}
        data={uploads}
        filterable
        sortable
        reorderable
        pageable={PAGEABLE_SETTINGS}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        resizable
        isLoading={isLoading}
        onContextMenuOpen={handleContextMenuOpen}
      >
        <Toolbar
          customElements={
            <StyledToolbarLeftDiv>
              <StyledActionButton type="button" onClick={toggleIsFilterModalOpen} variant={ButtonVariants.SECONDARY}>
                Filter
              </StyledActionButton>

              <StyledActionButton type="button" onClick={handleExportCSVClick} variant={ButtonVariants.SECONDARY}>
                Export as CSV
              </StyledActionButton>
            </StyledToolbarLeftDiv>
          }
        />
        <GridColumn title="Action" filterable={false} sortable={false} cell={ActionListCell} width="100px" />

        {UploadTrackerService.Columns.map((column, orderIndex) => {
          // check column orders
          // const { orderIndex, show } = UploadTrackerService.getColumnState(columnsState, column.field);

          // if (!show) {
          //   return null;
          // }

          return (
            <GridColumn
              key={column.field}
              {...column}
              headerCell={(headerCellProps: GridHeaderCellProps) => <HeaderCell {...headerCellProps} description={column.headerCellDescription} />}
              orderIndex={orderIndex}
              cell={column.cell}
              filter={column.columnFilter}
              columnMenu={column.columnMenu}
            />
          );
        })}
      </DataTable>
      <GatewayLogModal entityType="exam" show={isGatewayLogsOpen} suid={contextMenuUpload?.studyInstanceUID ?? null} onClose={closeGatewayLogs} />
      <Popup ref={contextMenuRef} onSelect={handleOnContextMenuSelect} isOpen={isContextMenuOpen} menuItems={ContextMenuItems} offset={contextMenuOffset} />
    </>
  );

  return (
    <Page>
      <PageHeader title="Upload Tracker" />
      <FilterModal
        show={isFilterModalOpen}
        columns={UploadTrackerService.Columns}
        initialFilter={dataState.filter ?? DEFAULT_EMPTY_FILTER}
        onClose={closeFilterModal}
        onSave={handleFilterModalSave}
      />

      {uploads && grid}
    </Page>
  );
};

const StyledToolbarLeftDiv = styled.div`
  display: flex;
  flex: 1 1 0;
`;
const StyledActionButton = styled(Button)`
  margin-right: ${({ theme }) => theme.space.spacing20};
`;
