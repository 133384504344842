import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { Form, FormElement, castKendoSubmitHandler } from 'core/forms';
import { Button, ButtonVariants, ComponentSizes, Icon } from 'core/ui';

import { InitialValues, PatientUtils } from 'features/patient';

import { PatientFormProps, PatientFormValues } from '../types';
import { PatientFormFields } from './PatientFormFields';

const PatientFormContentInner: FunctionComponent<{
  isEditMode?: boolean;
  isRequestMode?: boolean;
  isFormDisabled: boolean;
  onFormToggle?: () => void;
  buttonText?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (key: string) => any;
  valueSetter: (key: string, value: any) => void;
  onChange?: (values: PatientFormValues) => void | undefined;
  initialValues?: PatientFormValues;
  ageRanges: PatientAgeRangeModel[] | undefined;
  isOpo?: boolean;
  showRequiredError?: boolean;
  isSubmitting?: boolean;
}> = ({
  isEditMode = true,
  isFormDisabled,
  onFormToggle,
  isOpo,
  buttonText,
  valueGetter,
  valueSetter,
  onChange,
  initialValues,
  ageRanges,
  showRequiredError,
}) => {
  const [age, setAge] = useState<string>(PatientUtils.calculateAgeFromDob(initialValues?.dob ?? null));

  //set initial age range from dob
  useEffect(() => {
    const dob = valueGetter('dob');

    if (dob) {
      valueSetter('ageRange', {
        value: PatientUtils.calculateAgeRange(ageRanges, PatientUtils.calculateAgeFromDob(dob)),
      });
    }
  }, [valueGetter, ageRanges, valueSetter]);

  const handleFormChange = useCallback(() => {
    setAge(PatientUtils.calculateAgeFromDob(valueGetter('dob')));

    // force the value to be matched by dob
    const dob = valueGetter('dob');
    if (dob) {
      valueSetter('ageRange', {
        value: PatientUtils.calculateAgeRange(ageRanges, PatientUtils.calculateAgeFromDob(dob)),
      });
    }

    const isUnosUnavailable = valueGetter('isUnosUnavailable');
    if (isUnosUnavailable) {
      valueSetter('unosID', { value: 'Unknown' });
    }
    onChange?.({
      id: valueGetter('id'),
      unosID: isUnosUnavailable ? 'Unknown' : valueGetter('unosID'),
      active: valueGetter('active'),
      firstName: valueGetter('firstName'),
      lastName: valueGetter('lastName'),
      dob: valueGetter('dob'),
      patientNumber: valueGetter('patientNumber'),
      caseID: valueGetter('caseID'),
      height: valueGetter('height'),
      weight: valueGetter('weight'),
      gender: valueGetter('gender'),
      crossClampDateTime: valueGetter('crossClampDateTime'),
      notes: valueGetter('notes'),
      hospital: valueGetter('hospital'),
      locationType: valueGetter('locationType'),
      ageRange: valueGetter('ageRange'),
      location: valueGetter('location'),
      isUnosUnavailable: valueGetter('isUnosUnavailable'),
      location_Id: valueGetter('location_Id'),
    });
  }, [ageRanges, onChange, valueGetter, valueSetter]);

  const displayValues: PatientFormValues = useMemo(
    () => ({
      ...(initialValues == null ? InitialValues.donorForm : initialValues),
    }),
    [initialValues],
  );

  const patientVerbiage = isOpo ? 'Donor' : 'Patient';

  return (
    <FormElement autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <Container fluid>
        <PatientFormFields
          isFormDisabled={isFormDisabled}
          displayValues={displayValues}
          handleFormChange={handleFormChange}
          isEditMode={isEditMode}
          ageRanges={ageRanges}
          age={age}
          isSubProperty={false}
          valueSetter={valueSetter}
          valueGetter={valueGetter}
          isOpo={isOpo}
        />
        <br />
        {!isFormDisabled && buttonText != null && (
          <Button variant={ButtonVariants.SECONDARY} type="button" onClick={onFormToggle} size={ComponentSizes.LARGE}>
            {buttonText}
          </Button>
        )}

        {isFormDisabled && (
          <Button type="button" onClick={onFormToggle} size={ComponentSizes.LARGE}>
            Edit
          </Button>
        )}
        {showRequiredError && (
          <div
            className={'k-messagebox k-messagebox-error'}
          >{`Required ${patientVerbiage} information is missing. Please update the ${patientVerbiage} criteria to Request a Read. `}</div>
        )}
      </Container>
    </FormElement>
  );
};

PatientFormContentInner.displayName = 'PatientFormContentInner';

export const PatientFormContent: FunctionComponent<PatientFormProps> = ({
  initialValues,
  isFormDisabled,
  isRequestMode,
  isEditMode,
  onFormToggle,
  buttonText,
  onSubmit,
  onChange,
  resetTimestamp,
  ageRanges,
  isOpo,
  showRequiredError,
  isSubmitting,
  formId,
}) => {
  return (
    <Form
      key={resetTimestamp?.toISOString()}
      id={formId}
      initialValues={initialValues}
      onSubmit={castKendoSubmitHandler(onSubmit)}
      ignoreModified
      render={({ onChange: valueSetter, valueGetter }) => {
        return (
          <PatientFormContentInner
            isFormDisabled={isFormDisabled}
            isEditMode={isEditMode}
            onFormToggle={onFormToggle}
            buttonText={buttonText}
            initialValues={initialValues}
            valueGetter={valueGetter}
            valueSetter={valueSetter}
            onChange={onChange}
            ageRanges={ageRanges}
            isOpo={isOpo}
            isRequestMode={isRequestMode}
            showRequiredError={showRequiredError}
            isSubmitting={isSubmitting}
          />
        );
      }}
    />
  );
};

PatientFormContent.displayName = 'PatientFormContent';
